<div
  class=""
  style="height: 90vh; width: 100%; overflow-y: scroll; padding-bottom: 50vh"
>
  <div style="position: fixed; width: 100%; height: 100%">
    <mat-drawer-container
      class="example-container"
      autosize
      style="width: 100%"
    >
      <mat-drawer
        mode="side"
        #drawer
        class="example-sidenav text-center"
        mode="side"
        [opened]="screenWidth > 840"
      >
        <div class="topNav">
          <div class="row">
            <div class="col-8">
              <a (click)="homepageHandler()" style="cursor: pointer">
                <img src="assets/images/jlivelogo.svg" class="logoClass" />
              </a>
            </div>
            <div class="col-4">
              <button
                (click)="drawer.toggle()"
                mat-icon-button
                *ngIf="screenWidth < 800"
              >
                <mat-icon>menu</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <br />
        <ul *ngIf="!isadmin" class="padClass">
          <!-- <a  [routerLink]="['/dashboard/payment']">
        <li class="nava">
          <mat-icon class="iconClass">payment</mat-icon
          ><span class="spanClass">Payment</span>
        </li></a
      > -->

          <mat-accordion>
            <mat-expansion-panel class="example-sidenav" expanded="!isadmin">
              <mat-expansion-panel-header class="specific-class">
                <mat-panel-title>
                  <a>
                    <li class="nava-accord" style="height: 70px">
                      <mat-icon class="iconClass">settings</mat-icon
                      ><span class="spanClass">Settings</span>
                    </li>
                  </a>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <a [routerLink]="['/dashboard/payment']">
                <li
                  class="nava"
                  id="item11"
                  (click)="setCurrentItem('item1')"
                  [ngClass]="{
                    active:
                      currentItem === 'item11' ||
                      pageTitle === 'Payment Accounts',
                    'has-border':
                      currentItem === 'item11' ||
                      pageTitle === 'Payment Accounts'
                  }"
                >
                  <mat-icon class="iconClass">keyboard_arrow_right</mat-icon>
                  <span class="spanClass">Payment Accounts</span>
                </li>
              </a>
              <!-- <a [routerLink]="['/dashboard/profile']">
            <li class="nava" id="item12" (click)="setCurrentItem('item12')"
            [ngClass]="{
              active: currentItem === 'item12'|| pageTitle==='Jewellers Profile',
              'has-border': currentItem === 'item12' || pageTitle==='Jewellers Profile'
            }">
              <mat-icon class="iconClass">keyboard_arrow_right</mat-icon>
              <span class="spanClass">Store Profile</span>
            </li></a
          > -->
              <a *ngIf="checkJew()" [routerLink]="['/dashboard/store_profile']">
                <li
                  class="nava"
                  id="item13"
                  (click)="setCurrentItem('item13')"
                  [ngClass]="{
                    active:
                      currentItem === 'item13' ||
                      pageTitle === 'User Roles and Permissions',
                    'has-border':
                      currentItem === 'item13' ||
                      pageTitle === 'User Roles and Permissions'
                  }"
                >
                  <mat-icon class="iconClass">keyboard_arrow_right</mat-icon>
                  <span class="spanClass">Roles & Counters</span>
                </li>
              </a>

              <a *ngIf="checkJew()" [routerLink]="['/dashboard/view_vendor']">
                <li
                  class="nava"
                  id="item14"
                  (click)="setCurrentItem('item14')"
                  [ngClass]="{
                    active: currentItem === 'item14' || pageTitle === 'Vendors',
                    'has-border':
                      currentItem === 'item14' || pageTitle === 'Vendors'
                  }"
                >
                  <mat-icon class="iconClass">keyboard_arrow_right</mat-icon>
                  <span class="spanClass">Vendors/Goldsmiths</span>
                </li>
              </a>

              <!-- <a [routerLink]="['/dashboard/jewellery_plans']">
            <li class="nava">

              ><span class="spanClass">Manage Plans</span>
            </li></a
          > -->
              <!-- <a [routerLink]="['/dashboard/all_reports']">
            <li class="nava">
              <mat-icon class="iconClass">keyboard_arrow_right</mat-icon
              ><span class="spanClass">Reports </span>
            </li></a
          > -->

              <!-- <a [routerLink]="['/dashboard/reports']" > <li class="nava"> <mat-icon class="iconClass">keyboard_arrow_right</mat-icon><span class="spanClass">Settlements </span></li></a>     -->
              <!-- <a [routerLink]="['/dashboard/customers']" > <li class="nava"> <mat-icon class="iconClass">keyboard_arrow_right</mat-icon><span class="spanClass">Customers </span></li></a>
      <a [routerLink]="['/dashboard/new-customer']" > <li class="nava"> <mat-icon class="iconClass">keyboard_arrow_right</mat-icon><span class="spanClass">New Customers </span></li></a>     -->
              <!-- <a [routerLink]="['/dashboard/reports']" > <li class="nava"> <mat-icon class="iconClass">keyboard_arrow_right</mat-icon><span class="spanClass">Settlements </span></li></a>   -->
              <!-- <a [routerLink]="['/dashboard/customers']" > <li class="nava"> <mat-icon class="iconClass">keyboard_arrow_right</mat-icon><span class="spanClass">Customers </span></li></a>     -->
            </mat-expansion-panel>
          </mat-accordion>
        </ul>
        <div class="support_whatsapp padClass">
          <a href="https://www.jotform.com/232131157722043">
            <div name="17" class="nava router_cursor">
              <img
                src="assets/images/WhatsApp.svg"
                class="iconClass"
                style="margin-left: 18px"
              />
              <span class="spanClass">Support</span>
            </div>
          </a>
        </div>
        <ul *ngIf="isadmin" class="padClass">
          <div *ngIf="!show_mobile_nav">
            <a [routerLink]="['/dashboard/dashboard']"
              ><li
                class="nava paddingtop"
                id="item1"
                (click)="setCurrentItem('item1')"
                [ngClass]="{
                  active: currentItem === 'item1' || pageTitle === 'Dashboard',
                  'has-border':
                    currentItem === 'item1' || pageTitle === 'Dashboard'
                }"
              >
                <mat-icon class="iconClass">dashboard</mat-icon
                ><span class="spanClass">Dashboard</span>
              </li></a
            >
            <a [routerLink]="['/dashboard/customer-details']">
              <li
                name="11"
                class="nava router_cursor"
                id="item8"
                (click)="setCurrentItem('item8')"
                [ngClass]="{
                  active: currentItem === 'item8' || pageTitle === 'Customers',
                  'has-border':
                    currentItem === 'item8' || pageTitle === 'Customers'
                }"
              >
                <mat-icon class="iconClass">person</mat-icon
                ><span class="spanClass">Customers</span>
              </li></a
            >
            <!-- <a [routerLink]="['/dashboard/rate']"
        ><li
          name="1"
        class="nava router_cursor"
          id="item2"
          (click)="setCurrentItem('item2')"
          [ngClass]="{
            active: currentItem === 'item2' || pageTitle==='Rate History',
            'has-border': currentItem === 'item2'|| pageTitle==='Rate History'
          }"
        >
          <mat-icon class="iconClass">monitoring</mat-icon
          ><span class="spanClass">Rate History</span>
        </li></a
      > -->

            <a (click)="goToPlans()">
              <li
                name="7"
                class="nava router_cursor"
                id="item3"
                (click)="setCurrentItem('item3')"
                [ngClass]="{
                  active:
                    currentItem === 'item3' ||
                    pageTitle === 'Customer Ledgers' ||
                    pageTitle === 'Installment' ||
                    pageTitle === 'Start Saving Plan',
                  'has-border':
                    currentItem === 'item3' || pageTitle === 'Customer Ledgers'
                }"
              >
                <!-- <mat-icon class="iconClass">notifications</mat-icon> -->
                <img
                  src="assets/images/piggy_side.svg"
                  class="iconClass"
                  style="margin-left: 18px"
                />
                <span class="spanClass">Jewellery Plans</span>
              </li></a
            >
            <!-- <a (click)="allowed_actions['SetupDigitalCoin'] ? goToDigitalCoin() : ''"> -->
            <a
              [routerLink]="[
                digiStatus === 'NOT_REGISTERED'
                  ? '/digital-coin/setup'
                  : digiStatus === 'REGISTERED'
                  ? '/digital-coin/bookings'
                  : ''
              ]"
              *ngIf="
                digiStatus === 'NOT_REGISTERED' || digiStatus === 'REGISTERED'
              "
            >
              <li
                name="21"
                class="nava router_cursor ms-1"
                id="item15"
                (click)="setCurrentItem('item15')"
                [ngClass]="{
                  active:
                    currentItem === 'item15' ||
                    pageTitle === 'Digital Coin' ||
                    pageTitle === 'Digital Coin Bookings',
                  'has-border':
                    currentItem === 'item15' || pageTitle === 'Digital Coin'
                }"
              >
                <img
                  class="iconClass mb-1"
                  width="14"
                  height="14"
                  src="assets/images/advance_booking_new.svg"
                />
                <span class="spanClass">Digital Coin</span>
              </li>
            </a>

            <!-- <mat-accordion>
          <mat-expansion-panel
            [expanded]="pageTitle==='Customer Ledgers' || pageTitle==='Start Saving Plan' || pageTitle==='Jewellery Plans' || pageTitle==='Installment'"
            class="example-sidenav">
            <mat-expansion-panel-header class="specific-class">
              <mat-panel-title>
                <a>
                  <li class="nava-accord">
                    <img src="assets/images/piggy_side.svg" class="iconClassnew" />
                      <span class="spanClass ms-1" style="margin-top:0;">Jewellery Plans
                      <mat-icon class="centerClass mb-1"></mat-icon>
                    </span>
                  </li>
                </a>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a [routerLink]="['/dashboard/customer_ledger']">
              <li name="7" class="nava router_cursor" id="item3" (click)="setCurrentItem('item3')" [ngClass]="{
                active: currentItem === 'item3' || pageTitle==='Customer Ledgers' || pageTitle==='Installment' || pageTitle==='Start Saving Plan',
                'has-border': currentItem === 'item3' || pageTitle==='Customer Ledgers'
              }">
                <mat-icon class="iconClass">keyboard_arrow_right</mat-icon><span class="spanClass">Customer Plans</span>
              </li>
            </a>
            <a [routerLink]="['/dashboard/jewellery_plans']">
              <li name="2" class="nava router_cursor" id="item4" (click)="setCurrentItem('item4')" [ngClass]="{
                active: currentItem === 'item4' || pageTitle==='Jewellery Plans',
                'has-border': currentItem === 'item4' || pageTitle==='Jewellery Plans'
              }"
            >
              <mat-icon class="iconClass">keyboard_arrow_right</mat-icon
              ><span class="spanClass">Setup</span>
            </li>
          </a>

</mat-expansion-panel>
</mat-accordion> -->

            <mat-accordion>
              <mat-expansion-panel class="example-sidenav">
                <mat-expansion-panel-header class="specific-class">
                  <mat-panel-title>
                    <a>
                      <li class="nava-accord">
                        <mat-icon class="iconClass" style="margin-top: 0"
                          >store</mat-icon
                        ><span class="spanClass" style="margin-top: 0"
                          >Webstore
                          <span class="badge badge-cool mt-1">New</span>
                          <mat-icon class="centerClass mb-1"></mat-icon>
                        </span></li
                    ></a>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <a [routerLink]="['/dashboard/catalogue']">
                  <li
                    name="16"
                    class="nava router_cursor"
                    id="item16"
                    (click)="setCurrentItem('item16')"
                    [ngClass]="{
                      active:
                        currentItem === 'item16' || pageTitle === 'Catalogue',
                      'has-border':
                        currentItem === 'item16' || pageTitle === 'Catalogue'
                    }"
                  >
                    <mat-icon class="iconClass">keyboard_arrow_right</mat-icon
                    ><span class="spanClass">Catalogue</span>
                  </li></a
                >
                <a [routerLink]="['/dashboard/enquiry']">
                  <li
                    name="17"
                    class="nava router_cursor"
                    id="item17"
                    (click)="setCurrentItem('item17')"
                    [ngClass]="{
                      active:
                        currentItem === 'item17' || pageTitle === 'Enquiry',
                      'has-border':
                        currentItem === 'item17' || pageTitle === 'Enquiry'
                    }"
                  >
                    <mat-icon class="iconClass">keyboard_arrow_right</mat-icon
                    ><span class="spanClass">Enquiry</span>
                  </li></a
                >
                <a [routerLink]="['/dashboard/estimation']">
                  <li
                    name="8"
                    class="nava router_cursor"
                    id="item18"
                    (click)="setCurrentItem('item18')"
                    [ngClass]="{
                      active:
                        currentItem === 'item18' || pageTitle === 'Estimation',
                      'has-border':
                        currentItem === 'item18' || pageTitle === 'estimation'
                    }"
                  >
                    <mat-icon class="iconClass">keyboard_arrow_right</mat-icon
                    ><span class="spanClass">Estimation</span>
                  </li></a
                >
              </mat-expansion-panel>
            </mat-accordion>

            <!--

        </mat-expansion-panel>
      </mat-accordion>
 -->

            <a (click)="goToAdvance()">
              <a [routerLink]="['/dashboard/view_bookings']">
                <li
                  name="14"
                  class="nava router_cursor"
                  id="item4"
                  (click)="setCurrentItem('item14')"
                  [ngClass]="{
                    active:
                      currentItem === 'item14' || pageTitle === 'Bookings',
                    'has-border':
                      currentItem === 'item14' || pageTitle === 'Bookings'
                  }"
                >
                  <!-- <mat-icon class="iconClass">notifications</mat-icon> -->
                  <img
                    src="assets/images/advance_booking_new.svg"
                    class="iconClass"
                    style="margin-left: 18px"
                  />
                  <span class="spanClass">Advance Booking</span>
                </li></a
              >
            </a>

            <a [routerLink]="['/dashboard/view_order']">
              <li
                name="12"
                class="nava"
                id="item11"
                (click)="setCurrentItem('oms')"
                [ngClass]="{
                  active: currentItem === 'oms' || pageTitle === 'Create Order',
                  'has-border':
                    currentItem === 'oms' || pageTitle === 'View Order'
                }"
              >
                <div class="ms-1">
                  <img
                    class="iconClass mb-1"
                    width="18"
                    height="18"
                    src="assets/images/oms.svg"
                  />
                </div>

                <span class="spanClass sidebar-label">Order</span>
                <span class="badge badge-cool mt-1">New</span>
              </li>
            </a>

            <a [routerLink]="['/dashboard/all_reports']">
              <li
                name="8"
                class="nava router_cursor"
                id="item5"
                (click)="setCurrentItem('item5')"
                [ngClass]="{
                  active: currentItem === 'item5' || pageTitle === 'Reports',
                  'has-border':
                    currentItem === 'item5' || pageTitle === 'Reports'
                }"
              >
                <!-- <mat-icon class="iconClass">notifications</mat-icon> -->
                <img
                  src="assets/images/file_.svg"
                  class="iconClass"
                  style="margin-left: 18px"
                />
                <span class="spanClass">Reports</span>
              </li>
            </a>
            <a [routerLink]="['/dashboard/reports']">
              <li
                name="9"
                class="nava router_cursor"
                id="item6"
                (click)="setCurrentItem('item6')"
                [ngClass]="{
                  active:
                    currentItem === 'item6' || pageTitle === 'Settlements',
                  'has-border':
                    currentItem === 'item6' || pageTitle === 'Settlements'
                }"
              >
                <img
                  src="assets/images/settlement.svg"
                  class="iconClass"
                  style="margin-left: 18px"
                /><span class="spanClass">Settlements</span>
              </li>
            </a>
            <a [routerLink]="['/dashboard/analytics']">
              <li
                name="12"
                class="nava ms-1"
                id="item11"
                (click)="setCurrentItem('item99')"
                [ngClass]="{
                  active: currentItem === 'item99' || pageTitle === 'Analytics',
                  'has-border':
                    currentItem === 'item99' || pageTitle === 'Analytics'
                }"
              >
                <img
                  class="iconClass mb-1"
                  width="18"
                  height="18"
                  src="assets/images/analytics.svg"
                />

                <span class="spanClass sidebar-label">Analytics</span>
                <span class="badge badge-cool mt-1">New</span>
              </li>
            </a>

            <a [routerLink]="['/dashboard/notification']">
              <li
                name="12"
                class="nava router_cursor ms-1"
                id="item7"
                (click)="setCurrentItem('item7')"
                [ngClass]="{
                  active:
                    currentItem === 'item7' ||
                    pageTitle === 'Send Notifications',
                  'has-border':
                    currentItem === 'item7' ||
                    pageTitle === 'Send Notifications'
                }"
              >
                <img
                  class="iconClass mb-1"
                  width="14"
                  height="14"
                  src="assets/images/notificationsidenav.svg"
                />
                <span class="spanClass">Send Notifications</span>
              </li>
            </a>

            <!-- <a [routerLink]="['/dashboard/payment']">
        <li class="nava">
          <mat-icon class="iconClass">payment</mat-icon
          ><span class="spanClass">Payment</span>
        </li></a
      > -->

            <!-- <a [routerLink]="['/dashboard/banners']">
        <li
          class="nava"
          id="item9"
          (click)="setCurrentItem('item9')"
          [ngClass]="{
            active: currentItem === 'item9' || pageTitle==='Banners',
            'has-border': currentItem === 'item9' || pageTitle==='Banners'
          }"
        >
          <mat-icon class="iconClass">photo</mat-icon
          ><span class="spanClass">Banners </span>
        </li></a
      > -->

            <a
              target="_blank"
              href="https://goldlane.notion.site/goldlane/FAQs-e8c05c711e63404a8dc28c41ba33b36c"
            >
              <li class="nava">
                <mat-icon class="iconClass">info</mat-icon
                ><span class="spanClass">User Guide</span>
              </li>
            </a>
            <!-- <a target="_blank" href="https://sms.jewellers.live/">
        <li
          class="nava"
          id="item10"
          (click)="setCurrentItem('item10')"
          [ngClass]="{
            active: currentItem === 'item10',
            'has-border': currentItem === 'item10'
          }"
        >
          <mat-icon class="iconClass">mail</mat-icon
          ><span class="spanClass">SMS Marketing</span>
        </li></a
      > -->

            <!-- <div class="logout-class">
    <li (click)="logout()" class="nava"> <mat-icon class="iconClass">logout</mat-icon><span class="spanClass">Logout</span></li>

  </div> -->
            <!-- <div class="bottom-logo">
    <img src="assets/images/bottom_logo.png">
  </div> -->
            <div class="version">Version {{ version }}</div>
          </div>
          <!-- <div *ngIf="!show_mobile_nav">
<a [routerLink]="['/dashboard/dashboard']"
><li
  class="nava paddingtop"
>
  <mat-icon class="iconClass">monitoring</mat-icon
  ><span class="spanClass">Rate Page</span>
</li></a
>
<a [routerLink]="['/dashboard/dashboard']"
><li
  class="nava"
>
<img class="iconClassnew" width="18" height="18" src="assets/images/analytics.svg"/>
<span class="spanClass">Analytics</span>
</li></a
>
</div> -->
          <div *ngIf="show_mobile_nav">
            <!-- <a [routerLink]="['/dashboard/mobile_rate']"
        ><li


          class="nava paddingtop"
          id="item1"
          (click)="setCurrentItem('item1');drawer.toggle()"
          [ngClass]="{
            active: currentItem === 'item1'|| pageTitle==='Dashboard',
            'has-border': currentItem === 'item1' || pageTitle==='Dashboard'
          }"
        >
          <mat-icon class="iconClass">monitoring</mat-icon
          ><span class="spanClass">Rate Page</span>
        </li></a
      >
      <a [routerLink]="['/dashboard/analytics']"
        ><li


          class="nava"
          id="item2"
          (click)="setCurrentItem('item2');drawer.toggle()"
          [ngClass]="{
            active: currentItem === 'item2'|| pageTitle==='Rate',
            'has-border': currentItem === 'item2' || pageTitle==='Rate'
          }"
        >
        <img class="iconClassnew" width="18" height="18" src="assets/images/analytics.svg"/>
        <span class="spanClass">Analytics</span>
        </li></a
      > -->
          
            <a>
              <li class="nava" id="item2" (click)="logout()">
                <img
                  class="iconClassnew"
                  width="18"
                  height="18"
                  src="assets/images/logout.svg"
                />
                <span class="spanClass">Logout</span>
              </li>
            </a>
          </div>
        </ul>
        <!-- <div class="belowNav">
      <h3>Powered by</h3>
      <img src="assets/images/goldlanelogofooter.png" class="logoClassNew">

     </div> -->
      </mat-drawer>

      <div
        id="desktop_nav_top"
        style="position: fixed; right: 0; width: 100%"
        class="shadow-sm"
      >
        <!-- <h1>{{title}}</h1> -->
        <mat-toolbar class="navbg">
          <button
            (click)="drawer.toggle()"
            mat-icon-button
            *ngIf="screenWidth < 800"
          >
            <mat-icon>menu</mat-icon>
          </button>
          <!-- <h3 (click)="back()" class="back_title">
        <mat-icon class="right_back">arrow_back_ios</mat-icon> {{pageTitle}}
      </h3> -->

          <div class="ttitle">{{ pageTitle }}</div>
          <span class="example-spacer"></span>

          <!-- <h3
        [matMenuTriggerFor]="menu"
        #trigger="matMenuTrigger"
        (mouseenter)="trigger.openMenu()"
        class="mr-5 mt-2 rate-history history-class right-float"
      > -->
          <h3
            [matMenuTriggerFor]="menu"
            #trigger="matMenuTrigger"
            class="mr-5 mt-2 rate-history optionstyle history-class right-float"
          >
            Gold Rate &nbsp;
            <p *ngIf="show_rate" class="rate">
              ₹{{
                display_rate[1].rate == "---"
                  ? 0
                  : (display_rate[1].rate | number : "1.2-2")
              }}
            </p>
            <mat-icon class="iconClass centerClass topClass"
              >arrow_drop_down</mat-icon
            >
          </h3>
          <mat-menu
            #menu="matMenu"
            class="p-2 drop"
            xPosition="after"
            [hasBackdrop]="true"
          >
            <div *ngIf="show_rate" class="grate">
              <div *ngFor="let data of display_rate">
                <p
                  mat-menu-item
                  *ngIf="data.rate != '---'"
                  (click)="gold = data.rate"
                  class="optionstyle"
                  style="font-size: 14px"
                >
                  {{
                    data.material.split("_").length > 1
                      ? data.material.split("_").join(" ") + "k"
                      : data.material
                  }}
                  <span class="marginLeft float_right rates"
                    >₹{{ data.rate | number : "1.2-2" }}</span
                  >
                </p>
              </div>
            </div>

            <button
              (click)="openRatePage()"
              style="color: #4070f4"
              mat-stroked-button
              color="primary"
            >
              Change Rates
            </button>
          </mat-menu>

          <img
            [matMenuTriggerFor]="profile"
            [src]="shop_image"
            class="user"
          /><mat-icon
            class="iconClass mt-3"
            style="margin-left: 8px"
            [matMenuTriggerFor]="profile"
            >arrow_drop_down</mat-icon
          >

          <mat-menu #profile="matMenu" class="profile">
            <!-- <table>
          <tr>
            <td>
              <span class="bold_font">Name </span>
            </td>
            <td><span class="bold_font marginLeft">:</span></td>
            <td>
              <span class="bold_font marginLeft"> {{ shop_name }}</span>
            </td>
          </tr>

          <tr class="mt-5">
            <td>
              <span class="bold_font">Id </span>
            </td>
            <td><span class="bold_font marginLeft">:</span></td>
            <td>
              <span class="bold_font marginLeft"> {{ shop_id }}</span>
            </td>
          </tr>

          <tr>
            <td>
              <span class="bold_font">Subdomain </span>
            </td>

            <td><span class="bold_font marginLeft">:</span></td>

            <td>
              <span class="bold_font marginLeft"> {{ shop_sub_domain }}</span>
            </td>
          </tr>
        </table> -->

            <!-- <hr />
        <li (click)="adminuse()" class="nava black">
          <mat-icon class="iconClass">settings</mat-icon
          ><span class="spanClass">Settings</span>
        </li>
        <li (click)="logout()" class="nava black">
          <mat-icon class="iconClass">logout</mat-icon
          ><span class="spanClass">Logout </span>
        </li> -->

            <div class="card" style="border: 0">
              <div class="card-body" style="padding-bottom: 5px">
                <div
                  class="d-flex text-black pb-3"
                  style="border-bottom: 1px solid #dfdfdf"
                >
                  <div class="flex-shrink-0">
                    <img
                      [src]="shop_image"
                      class="img-fluid"
                      style="width: 59px; border-radius: 50px"
                    />
                  </div>
                  <div class="flex-grow-1 ms-3 ddlabel1">
                    <div class="row" style="width: 240px">
                      <div class="col-md-12 ddlabel1">
                        <span class="ddlabel">Name: </span> {{ shop_name }}
                      </div>
                      <div class="col-md-12">
                        <span class="ddlabel">ID: </span>
                        {{ shop_id }}
                      </div>
                      <div class="col-md-12">
                        <span class="ddlabel">Subdomain : </span
                        >{{ shop_sub_domain }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="row mt-3 optionstyle"
                  (click)="adminuse()"
                  style="cursor: pointer"
                >
                  <img
                    src="assets/images/dropdownSetting.svg"
                    style="width: 45px"
                  />
                  Settings
                </div>
                <div
                  class="row mt-3 optionstyle"
                  (click)="logout()"
                  style="cursor: pointer"
                >
                  <img
                    src="assets/images/dropdownLogout.svg"
                    style="width: 45px"
                  />
                  Logout
                </div>
              </div>
            </div>
          </mat-menu>
        </mat-toolbar>
      </div>

      <div id="mobile_nav_top" class="shadow-sm">
        <!-- <h1>{{title}}</h1> -->
        <mat-toolbar class="navbg">
          <button
            (click)="drawer.toggle()"
            mat-icon-button
            *ngIf="screenWidth < 800"
          >
            <mat-icon>menu</mat-icon>
          </button>
          <!-- <h3 (click)="back()" class="back_title">
        <mat-icon class="right_back">arrow_back_ios</mat-icon> {{pageTitle}}
      </h3> -->
          <div *ngIf="show_mobile_nav" class="hi_jewel">Hi Jewellers</div>
          <div (click)="openNotification(notificaion)">
            <img src="assets/images/Notificationicon.svg" />
          </div>
        </mat-toolbar>
      </div>
      <div *ngIf="show_mobile_nav" class="bottom-tab">
        <mat-tab-group
          (selectedTabChange)="onTabChange($event)"
          [(selectedIndex)]="selectedTabIndex"
          headerPosition="below"
        >
          <mat-tab disableRipple>
            <ng-template mat-tab-label>
              <div class="icontab">
                <img id="activehome" src="assets/images/mobilehomeicon.svg" />
                <img
                  id="inactivehome"
                  src="assets/images/mobilehomeiconinactive.svg"
                />
                <div
                  class="mt-1"
                  [ngClass]="
                    selectedTabIndex == 0 ? 'active-title' : 'inactive-title'
                  "
                >
                  Home
                </div>
              </div>
            </ng-template>
            <!-- Tab content -->
          </mat-tab>
          <mat-tab disableRipple>
            <ng-template mat-tab-label>
              <div class="icontab">
                <img
                  height="25px"
                  width="25px"
                  src="assets/images/order_ring.svg"
                />
                <div
                  class="mt-1"
                  [ngClass]="
                    selectedTabIndex == 1 ? 'active-title' : 'inactive-title'
                  "
                >
                  Orders
                </div>
              </div>
            </ng-template>
            <!-- Tab content -->
          </mat-tab>
          <mat-tab disableRipple>
            <ng-template mat-tab-label>
              <div class="icontab">
                <mat-icon
                  id="inactiveCatalogue"
                  style="margin-top: 0; color: #4070f4"
                  >store</mat-icon
                >
                <mat-icon
                  id="activeCatalogue"
                  style="margin-top: 0; color: grey"
                  >store</mat-icon
                >
                <div
                  class="mt-1"
                  [ngClass]="
                    selectedTabIndex == 2 ? 'active-title' : 'inactive-title'
                  "
                >
                  Catalogue
                </div>
              </div>
            </ng-template>
            <!-- Tab content -->
          </mat-tab>

          <mat-tab disableRipple>
            <ng-template mat-tab-label>
              <div class="icontab">
                <img
                  id="inactiveanalytics"
                  src="assets/images/activeanalytics.svg"
                />
                <img
                  id="activeanalytics"
                  src="assets/images/inactiveanalytics.svg"
                />
                <div
                  class="mt-1"
                  [ngClass]="
                    selectedTabIndex == 3 ? 'active-title' : 'inactive-title'
                  "
                >
                  Analytics
                </div>
              </div>
            </ng-template>
            <!-- Tab content -->
          </mat-tab>
          <mat-tab disableRipple>
            <ng-template mat-tab-label>
              <div class="icontab">
                <svg
                  fill="#000000"
                  width="25px"
                  height="25px"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M187.698 120.105c41.846-15.492 89.309-5.554 121.432 26.561 44.941 44.941 44.941 117.826-.002 162.769-44.953 44.953-117.828 44.953-162.781 0-32.25-32.25-42.125-79.975-26.367-121.934 3.977-10.589-1.383-22.396-11.972-26.373s-22.396 1.383-26.373 11.972c-21.357 56.869-7.968 121.581 35.749 165.298 60.949 60.949 159.758 60.949 220.707 0 60.939-60.939 60.939-159.758 0-220.697-43.541-43.53-107.898-57.005-164.614-36.008-10.607 3.927-16.023 15.709-12.096 26.316s15.709 16.023 26.316 12.096z"
                  />
                  <path
                    d="M161.408 118.082l52.879 52.869c4.331 4.334 4.331 11.381-.001 15.713l-27.924 27.924c-4.341 4.341-11.373 4.341-15.714 0l-52.594-52.584c-7.999-7.997-20.966-7.996-28.963.003s-7.996 20.966.003 28.963l52.593 52.582c20.336 20.336 53.302 20.336 73.639-.001l27.924-27.924c20.326-20.326 20.326-53.297.006-73.634l-52.887-52.877c-7.999-7.997-20.966-7.996-28.963.003s-7.996 20.966.003 28.963zM836.42 904.635c-41.846 15.492-89.309 5.554-121.432-26.561-44.941-44.941-44.941-117.826.002-162.769 44.953-44.953 117.828-44.953 162.781 0 32.25 32.25 42.125 79.975 26.367 121.934-3.977 10.589 1.383 22.396 11.972 26.373s22.396-1.383 26.373-11.972c21.357-56.869 7.968-121.581-35.749-165.298-60.949-60.949-159.758-60.949-220.707 0-60.939 60.939-60.939 159.758 0 220.697 43.541 43.53 107.898 57.005 164.614 36.008 10.607-3.927 16.023-15.709 12.096-26.316s-15.709-16.023-26.316-12.096z"
                  />
                  <path
                    d="M862.712 906.659l-52.869-52.869c-4.34-4.34-4.34-11.377-.006-15.708l27.923-27.933c4.339-4.339 11.37-4.339 15.711.003l52.594 52.584c7.999 7.997 20.966 7.996 28.963-.003s7.996-20.966-.003-28.963l-52.593-52.582c-20.336-20.336-53.302-20.336-73.639.001l-27.917 27.927c-20.335 20.319-20.335 53.299.003 73.638l52.869 52.869c7.998 7.998 20.965 7.998 28.963 0s7.998-20.965 0-28.963zM674.469 738.186l-391.26-391.26c-7.998-7.998-20.965-7.998-28.963 0s-7.998 20.965 0 28.963l391.26 391.26c7.998 7.998 20.965 7.998 28.963 0s7.998-20.965 0-28.963zM343.768 279.258l400.374 400.374c7.998 7.998 20.965 7.998 28.963 0s7.998-20.965 0-28.963L372.731 250.295c-7.998-7.998-20.965-7.998-28.963 0s-7.998 20.965 0 28.963zm255.917 112.52l176.732-176.732c7.998-7.998 7.998-20.965 0-28.963s-20.965-7.998-28.963 0L570.722 362.815c-7.998 7.998-7.998 20.965 0 28.963s20.965 7.998 28.963 0zm214.393-149.914L631.53 422.641c-8.037 7.959-8.1 20.926-.141 28.963s20.926 8.1 28.963.141L842.9 270.968c8.037-7.959 8.1-20.926.141-28.963s-20.926-8.1-28.963-.141z"
                  />
                  <path
                    d="M945.721 131.005a20.48 20.48 0 014.873 21.176l-28.201 81.531a20.481 20.481 0 01-12.659 12.66l-81.541 28.211a20.48 20.48 0 01-21.179-4.874l-53.32-53.33a20.48 20.48 0 01-4.872-21.175l28.201-81.531a20.478 20.478 0 0112.658-12.659l81.531-28.211a20.478 20.478 0 0121.178 4.873l53.33 53.33zm-73.228-15.302l-60.012 20.765-20.758 60.014 35.194 35.201 60.021-20.766 20.758-60.012-35.202-35.202zm-421.165 544.57L208.763 902.838c-7.497 7.497-16.502 8.466-19.734 5.237l-74.541-74.541c-3.223-3.226-2.254-12.226 5.248-19.733l242.089-242.079c7.998-7.998 7.998-20.965.001-28.963s-20.965-7.998-28.963-.001L90.769 784.842c-22.28 22.295-26.003 56.877-5.249 77.648l74.553 74.553c20.778 20.76 55.375 17.036 77.654-5.243l242.565-242.565c7.998-7.998 7.998-20.965 0-28.963s-20.965-7.998-28.963 0z"
                  />
                </svg>

                <div
                  class="mt-1"
                  [ngClass]="
                    selectedTabIndex == 4 ? 'active-title' : 'inactive-title'
                  "
                >
                  Repairs
                </div>
              </div>
            </ng-template>
            <!-- Tab content -->
          </mat-tab>
        </mat-tab-group>
      </div>

      <!-- margin-top is used here , because the nav-topbar for desktop view is fixed, and hence to prevent all element from going under it
  we are putting a minimum margin away from top -->
      <div style="margin-top: 10vh">
        <router-outlet #outlet="outlet"></router-outlet>
      </div>
    </mat-drawer-container>

    <ng-template #notificaion let-modal>
      <div class="modal-body">
        <div class="croccs">
          <button
            type="button"
            class="close closeButton btn"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="notification_modal">
          <div>
            <img src="assets/images/girlnotification.svg" />
            <div class="notification_title">No Notifications</div>
            <div class="notification_text">
              Don't worry, we will notify you when <br />
              something arrives
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
