import { NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { NgOtpInputModule } from  'ng-otp-input';
import {FormsModule,FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { MainNavComponent } from './main-nav/main-nav.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatCardModule} from '@angular/material/card';
import {MatAnchor, MatButtonModule} from '@angular/material/button';
import { ToastrModule } from 'ngx-toastr';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from 'src/environments/environment';
import { MessagingService } from './services/messaging.service';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { MatListModule } from '@angular/material/list';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';



import {MatExpansionModule} from '@angular/material/expansion';
import { AllReportsComponent } from './report/all-reports/all-reports.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { SelectUsersComponent } from './notify/select-users/select-users.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { NotificationAnalyticsComponent } from './notify/notification-analytics/notification-analytics.component';
import { CustomersComponent } from './customers/customers.component';
import { NewCustomerComponent } from './new-customer/new-customer.component';
import { TemplatesComponent } from './templates/templates.component';
import { CreateAccountComponent } from './create-account/create-account.component';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { PaymentComponent } from './payment/payment.component';
import { CreateComponent } from './customers/create/create.component';
import { DashboardnewComponent } from './dashboardnew/dashboardnew.component';

import { NgChartsModule } from "ng2-charts";

import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import {MatSelectModule} from '@angular/material/select';
import { InterceptorInterceptor } from './services/interceptor.interceptor';
import { JewellersProfileComponent } from './jewellers-profile/jewellers-profile.component';
import { StoreProfileComponent } from './store-profile/store-profile.component';
import { CapitalizePipe } from './capitalize.pipe';
import { CancelPlanComponent } from './cancel-plan/cancel-plan.component';
import { CommaRestrictionDirective } from './services/comma-restriction.directive';
import { AnalyticsComponent } from './analytics/analytics.component';
import { RatePageMobileComponent } from './rate-page-mobile/rate-page-mobile.component';

import { MatTooltipModule} from '@angular/material/tooltip';
import { IndianCurrencyPipe } from './services/indian-currency.pipe';
import { IndianNumerationPipe } from './indian-numeration.pipe';
import { CustomerLedgerComponent } from './customer_ledger/customer-ledger/customer-ledger.component';
import { MatTabsModule } from '@angular/material/tabs';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import * as FileSaver from 'file-saver';
import { RatecutComponent } from './rate_cut_feature/ratecut/ratecut.component';
import { ReportComponent } from './rate_cut_feature/report/report.component';
import {  NumbersOnlyDirective } from './numberonly.directive';

import { PrivacyComponent } from './privacy/privacy.component';
import { CatalogueComponent } from './catalogue/catalogue.component';
import { EnquiryComponent } from './enquiry/enquiry.component';
import { EstimationComponent } from './estimation/estimation.component';
import { ViewEstimationComponent } from './estimation/view-estimation/view_estimation.component';
import { EditEstimationComponent } from './estimation/edit-estimation/edit-estimation.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { UploadDialogComponent } from './upload-dialog/upload-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FileChunksUploadComponent } from './file-chunks-upload/file-chunks-upload.component';
import { ProgressSpinnerDialogComponent } from './progress-spinner-dialog/progress-spinner-dialog.component';
import { LottieModule } from 'ngx-lottie';

import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { ShowAllImagesDialogComponent } from './show-all-images-dialog/show-all-images-dialog.component';
import { MobileEstimationComponent } from './mobile-estimation/mobile-estimation.component';
import { MobileCatalogueComponent } from './catalogue-mobile/catalogue-mobile.component';
import { EditCatalogueComponent } from './catalogue/edit-catalogue/edit-catalogue.component';
import { provideClientHydration } from '@angular/platform-browser';


export function playerFactory(): any {
  return import('lottie-web');
}

import { BookingsComponent } from './rate_cut_feature/bookings/bookings.component';
import { TwoDigitDecimaNumberDirective } from './two-digit-decimal-number.directive';
import { ViewOrderComponent } from './oms/view-order/view-order.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { ByteToMbPipe } from './byte-to-mb.pipe';
import { ViewVendorComponent } from './oms/view-vendor/view-vendor.component';
import { UppercaseNoSpecialDirective } from './directives/uppercase-no-special.directive';
import { OrderListMobileComponent } from './orders-mobile/order-list-mobile/order-list-mobile.component';
import { ViewOrderMobileComponent } from './orders-mobile/view-order-mobile/view-order-mobile.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';import { FloatingActionsComponent } from './floating-actions/floating-actions.component';
import { ScreenTrackingService } from '@angular/fire/compat/analytics';
import {provideGoogleAnalytics} from '@hakimio/ngx-google-analytics';
import { InternetService } from './services/internet.service';
import { NormalRoutingModule } from './normal-nav/normal-routing.module';
import { NewSideNavComponent } from './new-side-nav/new-side-nav.component';
import { RouterLinkActive } from '@angular/router';
import { PrinterComponent } from './printer/printer.component';
import { RepairOrder } from './models/repairOrder';
import { RepairOrderComponent } from './oms/repair-order/repair-order.component';
import { ViewRepairMobileComponent } from './repairs-mobile/view-repair-mobile/view-repair-mobile.component';
import { RepairListMobileComponent } from './repairs-mobile/repair-list-mobile/repair-list-mobile.component';
import { CreateJewelleryPlanComponent } from './create-jewellery-plan/create-jewellery-plan.component';
import { PrimaryBtnComponent } from './digital-coin/common/primary-btn/primary-btn.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AddEmiComponent } from './add-installment-ui/add-emi/add-emi.component';
import { OfflinePageComponent } from './templates/offline-page/offline-page.component';
import { DeliveryDetailsComponent } from './digital-coin/delivery-details/delivery-details.component';
import { DeliveryTableComponent } from './oms/delivery-table/delivery-table.component';
import { CreateCertificateComponent } from './certificate/create-certificate/create-certificate.component';
import { ListCertificateComponent } from './certificate/list-certificate/list-certificate.component';
import { BasicTemplateComponent } from './certificate/certificate-templates/basic-template/basic-template.component';
import { NewCertificateComponent } from './certificate/new-certificate/new-certificate.component';
import { BackBasicTemplateComponent } from './certificate/certificate-templates/back-basic-template/back-basic-template.component';
import { LottiePopupComponent } from './templates/lottie-popup/lottie-popup.component';


@NgModule({
  declarations: [
    AppComponent,
    FloatingActionsComponent ,
    LoginComponent,
    MainNavComponent,
    AllReportsComponent,
    SelectUsersComponent,
    NotificationAnalyticsComponent,
    CustomersComponent,

    TemplatesComponent,
    CreateAccountComponent,
    CustomerDetailsComponent,
    PaymentComponent,
    CreateComponent,
    DashboardnewComponent,
    JewellersProfileComponent,
    StoreProfileComponent,
    UppercaseNoSpecialDirective,
    CapitalizePipe,
    CancelPlanComponent,
    AnalyticsComponent,
    RatePageMobileComponent,
    MobileEstimationComponent,
    IndianNumerationPipe,
    CustomerLedgerComponent,
    RatecutComponent,
    ReportComponent,

    NumbersOnlyDirective,

    PrivacyComponent,

    CatalogueComponent,
    EnquiryComponent,
    EstimationComponent,
    ViewEstimationComponent,
    EditEstimationComponent,
    UploadDialogComponent,
    FileChunksUploadComponent,
    ProgressSpinnerDialogComponent,
    DeleteDialogComponent,
    ShowAllImagesDialogComponent,
    MobileEstimationComponent,
    MobileCatalogueComponent,
    EditCatalogueComponent   ,
     TwoDigitDecimaNumberDirective,
    ViewOrderComponent,
    ByteToMbPipe,
    ViewVendorComponent,
    OrderListMobileComponent,
    ViewOrderMobileComponent,
    NewSideNavComponent,
    PrinterComponent,
    RepairOrderComponent,
    ViewRepairMobileComponent,
    RepairListMobileComponent,
    CreateJewelleryPlanComponent,
    AddEmiComponent,
    BookingsComponent,
    OfflinePageComponent,
    DeliveryTableComponent,
    CreateCertificateComponent,
    ListCertificateComponent,
    BasicTemplateComponent,
    NewCertificateComponent,
    BackBasicTemplateComponent,
    LottiePopupComponent,
    
    
    
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgOtpInputModule,
    MatCardModule,
    FormsModule,
    NgbDatepickerModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatSidenavModule,
    HttpClientModule,
    MatIconModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatTableModule,
    MatPaginatorModule,
    MatMenuModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatButtonModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatCheckboxModule,
    MatRadioModule,
    ToastrModule.forRoot(),
    NgChartsModule,
    NgbDropdownModule,
    MatSelectModule,
    MatTooltipModule,
    HammerModule,
    MatTabsModule,
    NgbTooltipModule,
    MatTableModule,
    NgxDropzoneModule,
    MatProgressBarModule,
    DragDropModule,
    NgxDropzoneModule,
    MatProgressBarModule,
    MatDialogModule,
    MatButtonToggleModule,
    MatListModule,
    MatBottomSheetModule,
    LottieModule.forRoot({ player: playerFactory }),
    CdkAccordionModule, 
    RouterLinkActive,
    PrimaryBtnComponent,
    NgSelectModule,
    


    ],

  bootstrap: [AppComponent],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorInterceptor,
    multi: true
  },MessagingService,provideClientHydration(),ScreenTrackingService,
  provideGoogleAnalytics('G-EB5F6PW9NE'),
  { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
],

})


export class AppModule { }
