import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RolepermissionService } from './rolepermission.service';
import { ToastrService } from 'ngx-toastr';
import { CounterDataSource } from './store-profile.dataSource';
import { ResponseMessage } from '../models/message';
import { Actions, Groups, Roles } from '../models/roles';
import { PopUpManager } from '../manager/Popup';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TitleService } from '../services/title.service';
import { Router } from '@angular/router';
import { Console } from 'console';

const ELEMENT_DATA: any[] = [
  { position: 1, name: 'John', weight: 60 },
  { position: 2, name: 'Jane', weight: 55 },
  { position: 3, name: 'Bob', weight: 70 },
];

@Component({
  selector: 'app-store-profile',
  templateUrl: './store-profile.component.html',
  styleUrls: ['./store-profile.component.css'],
})
export class StoreProfileComponent {
  @ViewChild('focuscounterid') focus_counter_id!: ElementRef;
  status_n = '';
  search = '';
  email = '';
  ordering: string = '-id';

  pages_group: any = []

  copy_pages_groups: any;
  is_permission_edited : boolean =true;
  //roles id and name
  //add new conunter
  roles_id_add!: any;
  responseMessage: any = ResponseMessage;
  //input model
  storeID = localStorage.getItem('shop_id');
  counter_id!: any;
  pin!: any;
  role!: any;
  role_id!: any;
  dataSource!: CounterDataSource;

  //actions on table
  remove_counter_id!: any; //for changing status

  //otp model
  change_counter_pin_id!: any; //for changing pin
  new_pin!: any;
  confirm_pin!: any;

  status!: any;

  //showing pages
  show_edit_permission = false;
  show_page = true;

  //selected Counter /row
  selected_counter_id!: any;

  disable_save = true;
  //roles model
  roles!: Roles;
  roles_Arr: Roles[] = [];
  groups:Groups[]=[];
  nroles!: Roles;
  actions!: Actions;
  actions_Arr: Actions[] = [];

  items = [
    {
      value: 'Rate History',
      selected: false,
      actions: ['Add Rates', 'Edit Commodity Name', 'Filter Date'],
    },
    {
      value: 'Manage Plans',
      selected: false,
      actions: [
        'Add Plan Model',
        'Pause Plan Model',
        'Edit Plan Model',
        'Delete Plan Model',
      ],
    },
    { value: 'Settings-Payment Accounts', selected: false },
    { value: 'Start Jewellery Plan', selected: false },
    { value: 'Add Installment', selected: false },
    { value: 'Installment Page', selected: false },
    { value: 'Customer Ledger', selected: false },
    { value: 'Reports', selected: false },
    { value: 'Settlement', selected: false },
    { value: 'Settlement Details', selected: false },
    { value: 'Customers', selected: false },
    { value: 'Send Notifications', selected: false },
  ];
  popUpManager!: PopUpManager;
  selected_actions: any = [];
  selected_features: any = [
    {value:'General',status:true,active:true},
    {value:'Jewellery Plans',status:true,active:false},
    {value:'Digital Gold',status:true,active:false},
    {value:'Advance Booking',status:true,active:false},
    // {value:'Webstore',status:false},
    {value:'Order', status:true ,active:false},
    {value:'Repair',status:true,active:false},
  ];
  selectedRow: any;
  selected_role_id: any;

  selectRow(row: any) {
    this.selectedRow = row;
  }

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  // dataSource = new MatTableDataSource(ELEMENT_DATA);

  displayedColumns: string[] = [
    'serialNo',
    'counter_id',
    'role',
    'permissions',
    'status',
    'status_t',
    'actions',
  ];
  counter_id_display: any;
  constructor(
    private modalService: NgbModal,
    private rolepermissionservice: RolepermissionService,
    private toastr: ToastrService,
    private snackBar: MatSnackBar,
    private titleService: TitleService,
    private router: Router,
  ) {}

  ngOnInit(): void {

    this.titleService.setTitle('User Roles and Permissions');
    this.dataSource = new CounterDataSource(this.rolepermissionservice);
    this.dataSource.loadCounterAccount(
      0,
      this.search,
      this.ordering,
      this.status_n
    );
    this.popUpManager = new PopUpManager(this.snackBar);

    setTimeout(() => {
      var new1 = <HTMLInputElement>(
        document.getElementById('show_edit_permission')
      );
      new1.style.display = 'none';
    }, 1);
  }
  ngAfterViewInit(): void {
    this.dataSource.counter$.subscribe((count) => {
      this.paginator.length = count;
    });
    this.paginator.page.subscribe(() => {
      this.popUpManager.openSnackBar('Loading', 'cancel');

      this.dataSource.loadCounterAccount(
        this.paginator.pageIndex,
        this.search,
        this.ordering,
        this.status
      );
    });
  }
  sortbyId() {
    if (this.ordering == 'id') {
      this.ordering = '-id';
      this.resetAndDisplayTable();
    } else {
      this.ordering = 'id';
      this.resetAndDisplayTable();
    }
  }

  searchOption() {
    this.resetAndDisplayTable();
  }

  clearInput() {
    this.search = '';
  }
  resetAndDisplayTable() {
    this.popUpManager.openSnackBar('Loading', 'cancel');
    this.paginator.pageIndex = 0;
    this.dataSource.loadCounterAccount(
      0,
      this.search,
      this.ordering,
      this.status
    );
  }

  selected_toggle!: Actions;

  selectedValue = 'option1';
  options = [
    { label: 'Owner', value: '1' },
    { label: 'Sales', value: '2' },
    { label: 'Accounting', value: '3' },
    { label: 'Marketing', value: '4' },
    { label: 'CA', value: '5' },
    { label: 'Manager', value: '6' },
  ];
  modal(content: any) {
    this.modalService.open(content, { centered: true });
    setTimeout(() => {
      (<HTMLInputElement>document.getElementById('counterid')).focus()
    }, 100);
  }

  removeCounter() {
    this.rolepermissionservice.deleteCounter(this.remove_counter_id).subscribe(
      (data: any) => {
        this.toastr.success('', data.message, {
          positionClass: 'toast-bottom-right',
        });
        this.resetAndDisplayTable()
      },
      (error: any) => {
        this.toastr.info(
          '',
          'Something went wrong, please do try again later.',
          { positionClass: 'toast-bottom-right' }
        );
      }
    );
  }

  change_mode_modal(content:any,data:any)
  {
    this.modalService.open(content,{centered:true})
    this.selected_role_id=data;
  }

  change_mode() {
    this.rolepermissionservice
      .change_mode(this.selected_counter_id, this.selected_role_id)
      .subscribe(
        (data: any) => (
          // new Roles(data.id,data.name,data.status,data.actions)
          (this.roles_Arr = data.roles),
          (this.roles = data.roles[0].actions),
          (this.role = data.role_name),
          (this.role_id = data.role_id)


        )
      );
      for (var j = 0; j < this.roles_Arr.length; j++) {
        for (var i = 0; i < this.roles_Arr[j].actions.length; i++) {
          this.actions_Arr.push(this.roles_Arr[j].actions[i]);
        }
      }
  }

  generalPages() {
    this.groups=[];
    const general = this.roles_Arr.filter((item) => item.type == 'GEN');
    this.groups.push(new Groups('General', general));
    const jewellery = this.roles_Arr.filter((item)=>item.type=='JWPl');
    this.groups.push(new Groups('Jewellery Plans', jewellery));
    const digital_gold = this.roles_Arr.filter((item)=>item.type=='DIG_COIN');
    this.groups.push(new Groups('Digital Gold', digital_gold));
    const advance = this.roles_Arr.filter((item)=>item.type=='ADVB');
    this.groups.push(new Groups('Advance Booking', advance));
    const order = this.roles_Arr.filter((item)=>item.type=='ORDR');
    this.groups.push(new Groups('Order', order));
    const repair = this.roles_Arr.filter((item)=>item.type=='RPR');
    this.groups.push(new Groups('Repair', repair));
    console.log(this.groups)

   
    this.featureClick('General')
    if(localStorage.getItem('roles')=='true'){return}
    // this.selected_features.map((i:any)=>{i.status=this.disableFeature(i.value)})
    // console.log(this.selected_features)
  }
  featureClick(item:any)
  {
    console.log(this.groups)
    const group:any=this.groups.find((i:any)=>i.name==item)
    this.pages_group=group.roles;
    this.selected_actions=[];
    var specific_item=this.selected_features.find((i:any)=>i.value==item)
    this.selected_features.map((i:any)=>i.active=false)
    specific_item.active=true;

    this.copy_pages_groups = JSON.stringify(this.pages_group);
    
  }


  disableFeature(item:any)
  {
    const g:any=this.groups.find((i:any)=>i.name==item)
    var checking :boolean =false;
    const check:any=g.roles.forEach((i:any)=>
    {if(i.status==true){checking=true}})
    return checking;
  }
  updateExistingRoles( newRoles: any): void {
    this.roles_Arr.map((existingRole, index) => {
        const newRole = newRoles[index];
        if (newRole) {
            existingRole.id = newRole.id;
            existingRole.name = newRole.name;
            existingRole.status = newRole.status;
            existingRole.actions = newRole.actions;
            // Update other properties as needed
        }

    });
}

  changeCounterPin(content: any) {
    if (this.new_pin === this.confirm_pin && this.new_pin && this.confirm_pin) {
      this.rolepermissionservice
        .changeCounterPin(this.change_counter_pin_id, this.remove_counter_id)
        .subscribe(
          (data: any) => {
            this.responseMessage = data;
            this.toastr.success('', data.message, {
              positionClass: 'toast-bottom-right',
            });
            this.new_pin = '';
            this.confirm_pin = '';
            this.modalService.open(content, { centered: true });
          },
          (error: any) => {
            this.toastr.info(
              '',
              'Something went wrong, please do try again later.',
              { positionClass: 'toast-bottom-right' }
            );
          }
        );
    } else {
      if (this.new_pin && this.confirm_pin) {
        if (this.new_pin.length < 4 || this.confirm_pin < 4) {
          this.toastr.info('', 'Please Type Valid Pin', {
            positionClass: 'toast-bottom-right',
          });
        } else {
          this.toastr.info('', 'Please Type Same Pin', {
            positionClass: 'toast-bottom-right',
          });
        }
      } else {
        this.toastr.info('', 'Please Type Valid Pin', {
          positionClass: 'toast-bottom-right',
        });
      }
    }

    this.new_pin = '';
    this.confirm_pin = '';
  }

  removeCounterModal(id: any, content: any) {
    this.remove_counter_id = id;
    this.modalService.open(content, { centered: true });
  }

  changePinCounterModal(id: any, content: any) {
    this.change_counter_pin_id = id;
    this.modalService.open(content, { centered: true });
  }

  addNewCounter() {

    this.rolepermissionservice
      .addCounter(this.counter_id, this.pin, this.roles_id_add)
      .subscribe(
        (data: any) => {
          this.responseMessage = data;
          this.toastr.success('', data.message, {
            positionClass: 'toast-bottom-right',
          });
          this.counter_id = null;
          this.pin = null;
          this.roles_id_add = null;
          this.resetAndDisplayTable()
        },
        (error: any) => {

          if (error.error.message == 'User already exist') {
            this.counter_id = null;
            this.toastr.info('', 'Please Change Counter ID', {
              positionClass: 'toast-bottom-right',
            });
          } else {
            this.toastr.info(
              '',
              'Something went wrong, please do try again later.',
              { positionClass: 'toast-bottom-right' }
            );
          }
        }
      );
  }

  addCounterSetPin(value: any) {
    if (value.length == 4) {
      this.pin = value;
    }
  }

  changeCounterNewPin(value: any) {
    if (value.length == 4) {
      this.new_pin = value;
    }
  }

  changeCounterPinConfirm(value: any) {
    if (value.length == 4) {
      this.confirm_pin = value;
    }
  }

  // getCounter() {
  //   this.rolepermissionservice.getCounter().subscribe((data: any) => {
  //     console.log(data);
  //   });
  // }

  changeCounterStatus(id: any, status: any) {
    this.rolepermissionservice.changeStatus(id, status).subscribe(
      (data: any) => {
        this.responseMessage = data;
        this.toastr.success('', data.message, {
          positionClass: 'toast-bottom-right',
        });
      },
      (error: any) => {
        this.toastr.info(
          '',
          'Something went wrong, please do try again later.',
          { positionClass: 'toast-bottom-right' }
        );
      }
    );
  }

  page_to_actions(item: any) {

    if (item) {
      this.selected_actions = [];
    }
    if (item.status) {
      for (let i = 0; i < item.actions.length; i++) {
        item.actions[i].status = false;
        item.actions[i].disable=true;

    }
    }
    else{
      for (let i = 0; i < item.actions.length; i++) {
        // item.actions[i].status = true;
        item.actions[i].disable=false;
    }
  }
    for (let i = 0; i < item.actions.length; i++) {
      const ite = item.actions[i];
      this.selected_actions.push(ite);
    }
    this.disable_save = false;

  }
  action_to_page(item:any)
  {

    if(item)
    {
      this.pages_group.find((i:any)=>i.actions.find((j:any)=>j.id==item.id)).status=true;
      item.status=true;
      this.deactivateSaveButton()
    }
    this.disable_save = false;
    console.log(this.pages_group)
  
  }

  deactivateSaveButton()
  {
    // console.log(this.copy_pages_groups)
    // console.log(JSON.stringify(this.pages_group))
    // if (JSON.stringify(this.copy_pages_groups) != JSON.stringify(this.pages_group)) {

      this.is_permission_edited = false;
    // }
  }
  pageClick(item:any)
  {

    if (item) {
      this.selected_actions = [];
    }

    if (item.status) {
      for (let i = 0; i < item.actions.length; i++) {

        item.actions[i].disable=false;
        

    }
    }
    else{
      for (let i = 0; i < item.actions.length; i++) {
        // item.actions[i].status = true;
        item.actions[i].disable=true;
       
    }
  
  }

    for (let i = 0; i < item.actions.length; i++) {
      const ite = item.actions[i];

      this.selected_actions.push(ite);
    }
  
    

  }

  switchToTable() {
    // this.show_edit_permission=!this.show_edit_permission;
    // this.show_page=!this.show_page;
    var new2 = <HTMLInputElement>document.getElementById('show_page');
    new2.style.display = 'block';
    var new1 = <HTMLInputElement>(
      document.getElementById('show_edit_permission')
    );
    new1.style.display = 'none';
  }

  switchToPermission(id: any, counter_id: any) {
    // this.show_edit_permission=!this.show_edit_permission;
    // this.show_page=!this.show_page;
    this.counter_id_display = counter_id;

    this.selected_counter_id = id;

    var new2 = <HTMLInputElement>(
      document.getElementById('show_edit_permission')
    );
    new2.style.display = 'block';
    var new1 = <HTMLInputElement>document.getElementById('show_page');
    new1.style.display = 'none';

    this.getPermission();
  }

  getPermission() {
    this.rolepermissionservice
      .getPermission(this.selected_counter_id)
      .subscribe(
        (data: any) => (
          console.log(data),
          // new Roles(data.id,data.name,data.status,data.actions)
          (this.roles_Arr = data.roles),
          (this.roles = data.roles[0].actions),
          (this.role = data.role_name),
          (this.role_id = data.role_id),
          (this.generalPages())
        )

      );



  }

  editPermission() {
    const new1 = [];
    for (let i = 0; i < this.roles_Arr.length; i++) {
      // new1.push(this.roles_Arr[i]);
      const arr = [];
      for (let j = 0; j < this.roles_Arr[i].actions.length; j++) {
        const add = new Actions(
          this.roles_Arr[i].actions[j].id,
          this.roles_Arr[i].actions[j].name,
          this.roles_Arr[i].actions[j].status
        );
        arr.push(add);

      }
      this.nroles = new Roles(
        this.roles_Arr[i].id,
        this.roles_Arr[i].name,
        this.roles_Arr[i].status,
        arr
      );
      new1.push(this.nroles);
    }


    this.rolepermissionservice
      .editPermission(this.selected_counter_id, new1)
      .subscribe((data: any) => {



        this.toastr.success('', data.message, {
          positionClass: 'toast-bottom-right',
        });
        setTimeout(() => {
          window.location.reload()
        }, 1000);

      }

      ),
      (error: any) => {
        this.toastr.info(
          '',
          'Something went wrong, please do try again later.',
          { positionClass: 'toast-bottom-right' }
        );
      };
  }
}
