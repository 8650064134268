<div class="flex_center mx-3">
    <div class="mat-elevation table-responsive">
      <table mat-table [dataSource]="deliveryDataSource" class="mat-table-container">
        <!-- Column 1 -->
  
        <!-- Column 2 -->
        <ng-container matColumnDef="delivery_date">
          <th mat-header-cell *matHeaderCellDef>Delivery Date</th>
          <td mat-cell *matCellDef="let element">{{ element.date }}</td>
        </ng-container>
        <ng-container matColumnDef="order_no">
            <th mat-header-cell *matHeaderCellDef>Order No</th>
            <td
              mat-cell
              *matCellDef="let element"
              (click)="element.is_assigned_to_vendors?'':redirectToOrder(element.custom_order_id)">
              <div 
              style="color: #3268fb">
                {{ element.code }}
              </div>
            </td>
          </ng-container>
        <ng-container matColumnDef="customer">
          <th mat-header-cell *matHeaderCellDef>Customer</th>
          <td mat-cell *matCellDef="let element" class="hover-text-overflow">
            <div class="tooltip-content">{{ element.name }}</div>
            <span class="tooltip-text" [matTooltip]="element.name">{{ element.name }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="item">
          <th mat-header-cell *matHeaderCellDef>Item</th>
          <td mat-cell *matCellDef="let element" class="hover-text-overflow">
            <div class="tooltip-content">{{ element.item }}</div>
            <span class="tooltip-text" [matTooltip]="element.item">{{ element.item }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="weight">
          <th mat-header-cell *matHeaderCellDef>Weight</th>
          <td mat-cell *matCellDef="let element">{{ element.weight }}g</td>
        </ng-container>
      
        <ng-container matColumnDef="invoice_no">
            <th mat-header-cell *matHeaderCellDef>Invoice No</th>
            <td mat-cell *matCellDef="let element">{{ element.invoice_no }}</td>
          </ng-container>
      
  
        <!-- Header row -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  
        <!-- Data row -->
        <tr
          mat-row
          [ngStyle]="{ 'background-color': row.status == 4 ? '#f0f0f0' : '' }"
          *matRowDef="let row; columns: displayedColumns"
          [class.selected]="row.selected"
        ></tr>
      </table>
  
      <mat-paginator
      class="page_"
        style="background-color: #f5f6f8"
        [pageSizeOptions]="[popUpManager.page_size_number]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>