import { NgFor, NgIf, NgStyle, formatDate } from '@angular/common';
import { AfterViewInit, Component, DestroyRef, ElementRef, HostListener, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
// import { OtpTemplateComponent } from '../common/otp-template/otp-template.component';
// import { PrimaryBtnComponent } from '../common/primary-btn/primary-btn.component';
// import { CustomerDetailsPopupComponent } from '../customer-details-popup/customer-details-popup.component';
// import { BackBtnDirective } from '../directives/back-btn.directive';
// import { UserDetails } from '../interfaces/digital-coin.interface';
// import { PaymentStatusPopupComponent } from '../payment-status-popup/payment-status-popup.component';
// import { PaymentsPopupComponent } from '../payments-popup/payments-popup.component';
// import { Commodity } from '../setup-save/setup-save.component';
// import { BuyTableData } from '../bookings/bookings.component';
import { Router } from '@angular/router';
import { TitleService } from 'src/app/services/title.service';
import { DigitalCoinService } from '../digital-coin/services/digital-coin.service';
import { OtpCommunicationService } from '../digital-coin/services/0tp-communication.service';
import { OtpTemplateComponent } from '../digital-coin/common/otp-template/otp-template.component';
import { UserDetails } from '../digital-coin/interfaces/digital-coin.interface';
import { PaymentsPopupComponent } from '../digital-coin/payments-popup/payments-popup.component';
import { BuyTableData } from '../digital-coin/bookings/bookings.component';
import { PaymentStatusPopupComponent } from '../digital-coin/payment-status-popup/payment-status-popup.component';
import { CustomerDetailsPopupComponent } from '../digital-coin/customer-details-popup/customer-details-popup.component';
import { SavingcustomerService } from '../savingcustomer/savingcustomer.service';
import { PlanName } from '../models/planname';
import { PaymentOptions, PaymentType } from '../models/installments';
import { PaymentSummary } from '../models/orderDetail';
import { InstallmentServiceService } from '../create_launch_plans/add-installments/installment-service.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentManager } from '../manager/payment';
import { ActionService } from '../services/actions.service';
import { CustomerDetailsDialogComponent } from '../templates/customer-details-dialog/customer-details-dialog.component';

export interface AmountDetails {
  weight: number;
  rate: number;
  sub_total: number;
  va_amount: number;
  cgst_amount: number;
  igst_amount: number;
  sgst_amount: number;
  total_amount: number;
  round_off: number
}

export class SuccessPlanPopUp{
  plan_id: any;
  name: any;
  phone: any;
  plan_amount: any;
  payment_mode: any;
  installment: any;
  plan_type:any;
  weight:any;
  accmulated_amount:any;
}


@Component({
  selector: 'app-create-jewellery-plan',
  templateUrl: './create-jewellery-plan.component.html',
  styleUrls: ['./create-jewellery-plan.component.css','../oms/create-order/create-order.component.css','../create_launch_plans/add-installments/add-installments.component.css'],
})
export class CreateJewelleryPlanComponent {
  @ViewChild('inputField') inputField!: ElementRef;
  @ViewChild('commoditySelect') commoditySelect!: MatSelect;
  @ViewChild('nextInput') nextInput!: ElementRef;
  @ViewChild('weightInputField') weightInputField!: ElementRef;
  @HostListener('window:keydown', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key == 'e') {
      event.preventDefault()
      this.openCustomerDetails()
    } else if (event.key == "F5") {
      event.preventDefault()
      this.proceedToPayment()
    }
  }
  success_popup:SuccessPlanPopUp=new SuccessPlanPopUp();

  private otpReceiveHandler: (otp: string) => void = (otp) => {
    this.digiService.validateOtp(this.mobileNumber.value!, otp).subscribe(
      {
        next: res => {
          if (res.status == 200) {
            this.otpPopup?.close()
            this.mobileNumberVerified()
            this.openCustomerDetails()
          } else { this.wrongOtp.next(true); this.toastService.error(res.body.error.message) }
        },
        error: err => {
          this.wrongOtp.next(true)
          this.toastService.error(err.error.message ?? "Error Verifying OTP")
        }
      }
    )
  }
  account_list: any = [];
  currentDate = new Date();

  
  payment_manager!: PaymentManager;

  payment_arr: PaymentOptions[] = [
    new PaymentOptions(
      'Cash',
      [
        new PaymentType(
          '',
          '',
          formatDate(new Date(), 'yyyy-MM-dd', 'en-US'),
          '',
          ''
        ),
      ],
      7
    ),
  ];
  payment_summary: PaymentSummary = new PaymentSummary({});
  disable_payment_button: boolean = false;

  minimumPlanAmount: any;
  maxmum_plan_amount: any;
  get_plandataSource!: Array<PlanName>;
  changeInPlan: boolean = false;

  amount: any;
  plan_id: any;

  prefix: any = [];

  mobileVerified: boolean = false
  private otpPopup: MatDialogRef<OtpTemplateComponent> | undefined = undefined
  mobileNumber: FormControl<string | null> = new FormControl("", [Validators.required, Validators.pattern("[0-9]{10}")])
  wrongOtp: Subject<boolean> = new Subject() // TODO wrong otp event
  userDetails: UserDetails | undefined = undefined

  commditiesDropDownList: string[] = []
  selectedCommodity: string | undefined
  amountInput: number | "" = ""
  weightInput: any | "" = ""
  lastChangedInput: "amount" | "weight" = "amount"
  amountDetails: AmountDetails | undefined
  timeOutInprocess: boolean = false

  @ViewChild('payment_modal', { read: TemplateRef })
  payment_modal!: TemplateRef<any>;
  allowed_plan_amount!: boolean;
  allowed!: any;
  is_input_enabled: boolean = true;


  constructor(
    private matDialog: MatDialog,
    private destroyed: DestroyRef,
    private digiService: DigitalCoinService,
    private toastService: ToastrService,
    private otpCommunicationService: OtpCommunicationService,
    private router: Router,
    private renderer: Renderer2,
    private titleService: TitleService,
    private savingcustomer:SavingcustomerService,
    private toastr: ToastrService,
    private installmentService: InstallmentServiceService,
    private modalService: NgbModal,
    private actionService: ActionService


  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Start Jewellery Plan');
    this.otpCommunicationService.resendOtpClicked$.subscribe(() => {
      this.verifyOtp();
      this.otpPopup?.close();
    });
    this.allowed = this.actionService.getActions();

    
    this.getPlan();
    this.getPaymentAccounts();
    this.checkAllowed();

   
  }
  actionsCheck(value: any) {
    if (this.allowed == 'admin') {
      return true;
    }
    if (this.allowed.includes(value)) {
      return true;
    } else {
      return false;
    }
  }
  checkAllowed() {
    if (this.actionsCheck(15)) {
      this.allowed_plan_amount = true;
    } else {
      this.allowed_plan_amount = false;
    }
  }
  sendDataToPayment() {
   

    if (this.minimumPlanAmount <= Number(this.amount)) {
      this.openPaymentModel(this.payment_modal)
    } else {
      this.toastr.info('', 'Please Enter Valid Amount', {
        positionClass: 'toast-bottom-right',
      });
    }

  }


  createJewelleryPlan(content: any) {
    this.disable_payment_button=true
    // console.log(this.subs_ID?this.subs_ID:this.planID)
    this.installmentService
      .createInstallment(
        // this.installment_data,
        this.mobileNumber.value,
        this.amount,
        this.plan_id,
        this.payment_arr
        // this.subs_ID ? this.subs_ID : this.planID
      )

      .subscribe(
        (data: any) => {

          this.toastr.success('', data.message, {
            positionClass: 'toast-bottom-right',
          });
          this.modalService.dismissAll();
          this.router.navigate(['/dashboard/customer_ledger/']);
          this.success_popup=data;
          this.modalService.open(content, { centered: true });

        },
        (error) => {
          this.toastr.info('', error.error.message, {
            positionClass: 'toast-bottom-right',
          });
          this.disable_payment_button=false
        }
      );
  }
  getPaymentAccounts() {
    this.installmentService.getPayments().subscribe((data: any) => {
      for (let i of data.bank_accounts) {
        this.account_list.push(i);
      }
      for (let i of data.pos_accounts) {
        this.account_list.push(i);
      }
      console.log(this.account_list);
    });
  }

  ngAfterViewInit(): void {
    if (this.inputField && this.inputField.nativeElement) {
      this.inputField?.nativeElement.focus();
    }
    if (this.commoditySelect) {
      this.commoditySelect.focus();
    }
  }
  getPlan() {
    this.savingcustomer.getPlans().subscribe(
      (data: any) => {
        this.get_plandataSource = data.plans;
      },
      (error: any) => {
        this.toastr.info(
          '',
          'Something went wrong, please do try again later.',
          { positionClass: 'toast-bottom-right' }
        );
      }
    );
  }

  changeMinimumAmount(sendid: any) {
    this.plan_id=sendid;
    this.savingcustomer.getPlans().subscribe(
      (data: any) => {
        console.log(data);
        for (let i of data.plans) {
          if (Number(i.id) == Number(sendid)) {
            this.minimumPlanAmount = i.minimum;
            this.maxmum_plan_amount = i.maximum;
            this.is_input_enabled = i.is_prefix;
            this.prefix = i.prefix.split(',');
          }
        }
      },
      (error: any) => {
        this.toastr.info(
          '',
          'Something went wrong, please do try again later.',
          { positionClass: 'toast-bottom-right' }
        );
      }
    );
  }

  verifyOtp(): void {
    if (this.mobileNumber.valid) {
      this.digiService.sendOtp(this.mobileNumber.value!).subscribe(res => {
        if (res.status == 200) {
          this.toastService.success(res.body.message)
          let enterKeyEvent: Subject<string> = new Subject()
          let verifyClickEvent: Subject<string> = new Subject()
          let resendOtpEvent: Subject<void> = new Subject()

          enterKeyEvent.pipe(takeUntilDestroyed(this.destroyed)).subscribe(this.otpReceiveHandler)

          verifyClickEvent.pipe(takeUntilDestroyed(this.destroyed)).subscribe(this.otpReceiveHandler)
         
          resendOtpEvent.pipe(takeUntilDestroyed(this.destroyed)).subscribe(() => {
            this.digiService.sendOtp(this.mobileNumber.value!)
          this.focus('select_plan')
          })

          this.otpPopup = this.matDialog.open(OtpTemplateComponent, {
            width: "25vw", panelClass: "custom-dialog", data: {
              enterKeyEvent: enterKeyEvent,
              verifyClickEvent: verifyClickEvent,
              resendOtpEvent: resendOtpEvent,
              wrongOtp: this.wrongOtp
            }
          })
          this.focus('select_plan')
        } else this.toastService.error(res.body.error.message)

      }, err => {
        if (err.status == 400 && err.error.data) {
          this.mobileNumberVerified()
          this.userDetails = err.error.data
          this.focus('select_plan')

        } else {
          this.toastService.error(err.error.message)
        }
      })
    }
    else{
      this.toastService.error("Please Enter Valid Mobile Number")
    }
  }

  
  proceedToPayment(): void {
    if (this.amountDetails && this.mobileNumber.value) {
      this.matDialog.open(PaymentsPopupComponent,
        {
          width: "80vw", height: '40vw', panelClass: ["custom-dialog", "rm-padding"], data: {
            paymentSummary: this.amountDetails,
            phone: this.mobileNumber.value,
            commodity: this.selectedCommodity
          }
        }).afterClosed().subscribe((data: BuyTableData & { weight: string }) => {
          if (data) this.matDialog.open(PaymentStatusPopupComponent, {
            panelClass: ["custom-dialog", "rm-padding"],
            data: { details: data }
          }).afterClosed().subscribe(result => {
            if (result) {
              window.open(data.invoice_url, '_blank')
            }
            this.router.navigate(['/digital-coin', 'bookings'])
          })
        })
    } else this.toastService.warning("Please Select Commodity Type & Enter Amount or Weight")
  }
  openPaymentModel(content: any) {
    {
      this.modalService.open(content, {
        centered: true,
        size: 'xl',
        backdrop: 'static',
        modalDialogClass: 'pm',
      });
      // if(!this.order_id){
      //   this.getPaymentSummary();
      // }
      this.handleAdvance();
      setTimeout(() => {
        (<HTMLInputElement>document.getElementById('payment_amount_0')).focus();
      }, 100);
    
      return;
    }
  }
 
  
   // for preventing default events
   preventDefault(event: any) {
    event.preventDefault();
  }
  removePaymentRow(index: number) {
    if (this.payment_arr.length == 1) {
      var row = new PaymentOptions(
        '',
        [
          new PaymentType(
            '',
            '',
            formatDate(new Date(), 'yyyy-MM-dd', 'en-US'),
            '',
            ''
          ),
        ],
        7
      );
      this.payment_summary.advance =
        Number(this.payment_summary.advance) -
        Number(this.payment_arr[index].options[0].amount);
      this.payment_summary.balance =
        Number(this.payment_summary.balance) +
        Number(this.payment_arr[index].options[0].amount);
      console.log('this should come', this.payment_summary.balance);
      this.payment_arr[index] = row;
      (<HTMLInputElement>(
        document.getElementById('payment_amount_' + (index - 1))
      )).focus();
      return;
    } else {
      this.payment_summary.advance =
        Number(this.payment_summary.advance) -
        Number(this.payment_arr[index].options[0].amount);
      this.payment_summary.balance =
        Number(this.payment_summary.balance) +
        Number(this.payment_arr[index].options[0].amount);
      this.payment_arr.splice(index, 1);
      (<HTMLInputElement>(
        document.getElementById('payment_amount_' + (index - 1))
      )).focus();
    }
  }
  addPaymentRow(index: number) {
    var row = new PaymentOptions(
      '',
      [
        new PaymentType(
          '',
          '',
          formatDate(new Date(), 'yyyy-MM-dd', 'en-US'),
          '',
          ''
        ),
      ],
      7
    );
    if (
      JSON.stringify(this.payment_arr[this.payment_arr.length - 1]) ==
      JSON.stringify(row)
    ) {
      this.toastr.info('', 'Please Fill the Required Fields', {
        positionClass: 'toast-bottom-right',
      });
      return;
    }
    this.payment_arr.push(row);
    setTimeout(() => {
      const element = <HTMLDivElement>document.getElementById('pm_first');
      // payment_block?.scrollIntoView({behavior:'smooth'})
      if (element) {
        // element.scrollTop = element.scrollHeight;
        element.scrollTop = element.scrollHeight;
      }
      (<HTMLInputElement>(
        document.getElementById('payment_amount_' + (index + 1))
      )).focus();
    }, 100);
  }
  handleAdvance() {
    this.payment_summary.advance = this.payment_arr.reduce(
      (acc, curr) => Number(acc) + Number(curr.options[0].amount),
      0
    );
  }

  openCustomerDetails(): void {
    if (this.userDetails) this.userDetails!.mobile = this.mobileNumber.value!
    else this.userDetails = { mobile: this.mobileNumber.value! } as UserDetails
    this.matDialog.open(CustomerDetailsDialogComponent, {
      width: '80vw',
      panelClass: ["custom-dialog", "rm-padding"],
      data: {
        userDetails: this.userDetails
      }
    }).afterClosed().subscribe((userDetails: UserDetails) => {
      if (userDetails) this.userDetails = userDetails
      if (this.commoditySelect) {
        this.commoditySelect.focus();
      }
    })
  }

  mobileNumberVerified(): void {
    this.mobileVerified = true
  }





  goBackToFirstScreen(): void {
    this.userDetails = undefined;
    this.mobileVerified = false;
    this.mobileNumber.patchValue("")
    this.mobileNumber.removeValidators([Validators.required, Validators.pattern("[0-9]{10}")])
    this.mobileNumber.updateValueAndValidity()
    this.mobileNumber.addValidators([Validators.required, Validators.pattern("[0-9]{10}")])
    setTimeout(() => {
      if (this.inputField && this.inputField.nativeElement) {
        this.inputField.nativeElement.focus();
      }
    });
  }

  onclickBack(): void {
    if(this.mobileVerified) {
      this.mobileVerified = false;
      this.mobileNumber.patchValue("")
    } else {
      this.router.navigate(['/digital-coin', 'bookings'])
    }
    setTimeout(() => {
      if (this.inputField && this.inputField.nativeElement) {
        this.inputField.nativeElement.focus();
      }
    });
  }

  preventString(event: any): void {
    if (!+event.key && event.key !== '.' && event.key !== '0') event.preventDefault()
  }

  focus(id: string) {
    setTimeout(() => {
      const input = <HTMLInputElement>document.getElementById(id);
      input.focus();
    }, 100);
  }
  
  focusNextInput(event: any, inputName: string): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (!this.commoditySelect.panelOpen) {
        this.nextInput.nativeElement.focus();
      }
    }
  }
}
