import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  HostListener,
  SimpleChanges,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Chart, ChartType } from 'chart.js/auto';
import { RateServiceService } from '../rate-page/rate-service.service';
import { RateFinal, RateModel } from '../models/rate';
import { ToastrService } from 'ngx-toastr';
import { MainNavComponent } from '../main-nav/main-nav.component';
import { Dashboard } from '../models/dashboard';
import { DashboardnewService } from '../dashboardnew/dashboardnew.service';
import { DatePipe } from '@angular/common';
import { Doughnut } from '../models/graphdata';
import { IndianNumerationPipe } from '../indian-numeration.pipe';
import { TitleService } from '../services/title.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-rate-page-mobile',
  templateUrl: './rate-page-mobile.component.html',
  styleUrls: ['./rate-page-mobile.component.css'],
})
export class RatePageMobileComponent {
  gold22k!: Number;
  silver!: Number;
  gold18k!: Number;
  platinum!: Number;
  gold24!: Number;
  goldother!: Number;
  silver999!: Number;

  last_updated!: any;
  last_updated_time!: any;
  dashdata: Dashboard = new Dashboard();
  doughnut: Doughnut = new Doughnut();
  public chartDoughnut: any;
  front_page: any;
  middle_page: any;
  third_page: any;
  show_mobile_nav: any;
  rates!: RateModel[];
  middle_page_display!: boolean;
  third_page_display!: boolean;

  unassigned_order_count!: number;

  constructor(
    private modalService: NgbModal,
    private rateService: RateServiceService,
    private toastr: ToastrService,
    private main_nav: MainNavComponent,
    private dashboardservice: DashboardnewService,
    private rateservice: RateServiceService,
    private titleService: TitleService,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.rates = [];
    this.rateService.getLatestRates().subscribe((data: any) => {
      this.rates = data.rates;
      this.last_updated = data.last_updated;
      const last_updated = this.main_nav.rate_time;
      const datePipe = new DatePipe('en-US');
      this.last_updated = datePipe.transform(last_updated, 'dd/MM/yyyy');
      this.last_updated_time = datePipe.transform(last_updated, 'hh:mm a');
    });
    this.dashboardservice.getData().subscribe((data:any) => {
      this.dashdata = data;
      this.unassigned_order_count = data.unassinged_orders;
    });
    this.dashboardservice.getDoughnut().subscribe((data) => {
      this.doughnut = data;
      setTimeout(() => {
        this.chartDoughnut_();
      }, 1200);
    });

    setTimeout(() => {
      this.front_page = <HTMLDivElement>document.getElementById('front_page');
      this.middle_page = <HTMLDivElement>(
        document.getElementById('transaction_details_block')
      );
      this.third_page = <HTMLDivElement>(
        document.getElementById('change_rate_block')
      );
      this.middle_page.style.display = 'none';
      this.third_page.style.display = 'none';
    }, 100);
  }

  showTransaction() {
    this.front_page.style.display = 'none';
    this.middle_page.style.display = 'block';
  }

  backToHome() {
    this.front_page.style.display = 'block';
    this.middle_page.style.display = 'none';
    this.third_page.style.display = 'none';
  }
  showChangesRate() {
    this.front_page.style.display = 'none';
    this.middle_page.style.display = 'none';
    this.third_page.style.display = 'block';
  }
  routeToOrder()
  {
    
    this.router.navigate(['/dashboard/view_order_list_mobile',{is_unassigned:1}]);
  }

  showMobileEstimation() {
    this.router.navigate(['/dashboard/mobile_estimation']);
  }

  showMobileCatalogue() {
    this.router.navigate(['/dashboard/mobile_catalogue']);
  }

  chartDoughnut_()
  {

    const ctxDoughnut = <HTMLCanvasElement>(
      document.getElementById('myChartDoughnutRate')
    );
    const indianNumerationPipe = new IndianNumerationPipe();
    this.chartDoughnut = new Chart(ctxDoughnut, {
      type: 'doughnut',

      data: {
        datasets: [
          {
            label: '',
            data: [
              this.doughnut.completed_count,
              this.doughnut.in_progress_count,
              this.doughnut.cancelled_count,
            ],
            backgroundColor: [' #668DF6', '#3268FB', '#3268FB'],
            hoverOffset: 4,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        cutout: 80,
        onResize: function (chart, size) {
          var doughnutRadius =
            Math.min(ctxDoughnut.width, ctxDoughnut.height) / 2;
          this.cutout = doughnutRadius / 100;
        },
      },

      plugins: [
        {
          id: 'customPluginName',
          beforeDraw: (chart, args, opts) => {
            var width = this.chartDoughnut.width,
              height = this.chartDoughnut.height,
              ctx = this.chartDoughnut.ctx;
            ctx.restore();

            var fontSize = (height / 110).toFixed(2);
            ctx.font = fontSize + 'em rubik';

            ctx.textBaseline = 'middle';

            var text =
              '₹' +
              indianNumerationPipe.transform(this.doughnut.total_subscription);
            var newtext = `Total ${this.doughnut.total_count} Plans`,
              textX = Math.round((width - ctx.measureText(text).width) / 2),
              textY = height / 2;
            ctx.fillText(text, textX, textY);

            var fontSize = (height / 230).toFixed(2);
            ctx.font = fontSize + 'em rubik';

            var textx = Math.round(
              (width - ctx.measureText(newtext).width) / 2
            );
            var texty = height / 1.65;

            ctx.fillText(newtext, textx, texty);
            ctx.save();
          },
        },
      ],
    });
  }

  rateUpdate(content: any) {
    this.rateService
      .updateRates(
        Number(this.gold22k),
        Number(this.gold18k ? this.gold18k : 0),
        Number(this.silver),
        Number(this.platinum ? this.platinum : 0),
        Number(this.gold24 ? this.gold24 : 0),
        Number(this.goldother ? this.goldother : 0),
        Number(this.silver999 ? this.silver999 : 0)
      )
      .subscribe(
        (data: any) => {
          this.modalService.open(content, { centered: true });
          this.router.navigate(['/dashboard/dashboard']);
        },
        (error: any) => {
          console.log(error);
          this.toastr.info('', 'Please enter the mandatory fields', {
            positionClass: 'toast-bottom-right',
          });
        }
      );
  }
}
