import { offlineBankAccounts } from "./offlinebankaccount";

export class PaymentType{
    id!:string;
    amount !:string;
    date !:string;
    bank !:string;
    payment_info!:string;
    constructor(id:string,amount:string,date:string,bank:string,payment_info:string){
        this.id=id;
        this.amount=amount;
        this.date=date;
        this.bank = bank;
        this.payment_info=payment_info;
    }
}

export class InvoiceDetails{
    amount!:string;
    gst!:string;
    old_gold!:string;
    total!:Number;
    advance_paid!:string;
    balance!:Number;
    old_gold_weight!:string;
    constructor(data:any){
        this.amount=data.amount || 0;
        this.gst=data.gst || 0;
        this.old_gold=data.old_gold || 0;
        this.advance_paid=data.advance_paid || 0;
        this.total=Number(this.amount)+Number(this.gst)-Number(this.old_gold) || 0;
        this.balance=Number(this.total) - Number(this.advance_paid) || 0;
        this.old_gold_weight=data.old_gold_weight || 0;
    }
}
export class InvoiceDetailsForRepair{
    amount!:string;
    advance_paid!:string;
    balance!:Number;
    constructor(data:any){
        this.amount=data.amount || 0;
        this.advance_paid=data.advance_paid || 0;
        this.balance=Number(this.amount) - Number(this.advance_paid) || 0;
    }
}

export class ResposnePayment{
    amount!:string;
    date!:string;
    offline_bank_account!:string;
    payment_mode!:string;
    payment_info!:string;
    constructor(amount:string,date:string,offline_bank_account:string,payment_mode:string)
    {
        this.amount=amount;
        this.date=date;
        this.offline_bank_account=offline_bank_account;
        this.payment_mode=payment_mode
    }
}
export class PaymentOptions{
    type !:string;
    mode!:number;
    options !:PaymentType[]
    constructor(type:string,options:PaymentType[],mode:number){
        this.type = type;
        this.options = options;
        this.mode=mode;
    }
        checkMissingAmount(): boolean {
            return this.options.some(option => option.amount.trim() === '');
        }
}

export class AdvancePaymentType{
    id!:string;
    mode!:string;
    amount !:string;
    date !:string;
    bank !:string;
    payment_info!:string;

    constructor(id:string,method:string,amount:string,date:string,bank:string,payment_info:string){
        this.id=id;
        this.mode=method;
        this.amount=amount;
        this.date=date;
        this.bank = bank;
        this.payment_info=payment_info;
    }
}




export class InstallmentData{
    plan_id !:string;
    date !:string;

    amount !:string;
    installment_number !:string;
    rate !:string;
    customer_name !:string;
    phone !:string;
    s_date!:string;
    weight !:string;
    last_paid !:string;
    constructor(plan_id:string,date:string,amount:string,installment_number:string,rate:string,customer_name:string,phone:string,weight:string,last_paid:string){
        this.plan_id = plan_id;
        this.date = date;

        this.amount = amount;
        this.installment_number=installment_number;
        this.rate = rate;
        this.customer_name = customer_name;
        this.phone = phone;
        this.weight = weight;
        this.last_paid = last_paid;

    }

}


export class InstallmentDataFromID  //subscription id
{
    

    amount!:string;
    installment!:string;
    name!:string;
    rate!:string;
    phone!:string;
    weight!:string;
    s_date!:string;
    admin!:boolean
    date!:string
    plan_type!:string;
    last_paid!:string;

}


export class InstallmentPrint{
    rc!:number;
    ins!:number;
    date!:string;
    plan_id!:string;
    rs!:string;
    wt!:string;
    rt!:string;
    tw!:string;

}