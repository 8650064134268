import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RateFinal } from '../models/rate';
import { RateServiceService } from '../rate-page/rate-service.service';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs';
import { TitleService } from '../services/title.service';
import { Location } from '@angular/common';
import { PageAccessService } from '../services/pageaccess.service';
import { injectClarity } from '../services/scriptInjection.service';
import { environment } from 'src/environments/environment.prod';
import { InterceptorInterceptor } from '../services/interceptor.interceptor';
import { RatePageComponent } from '../rate-page/rate-page.component';
import { PlanServiceService } from '../create_launch_plans/plan-service.service';
import { SIPPlan } from '../models/plan';
import { Rest } from '../models/rest_model';
import { RateCutService } from '../rate_cut_feature/ratecut/rate-cut.service';
import { DigitalCoinService } from '../digital-coin/services/digital-coin.service';

declare var $: any;

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css'],
})
export class MainNavComponent implements OnInit {
  isadmin = true; //for later use
  showFiller = false;
  screenHeight!: number;
  screenWidth!: number;
  rates!: RateFinal;
  shop_image = '';
  shop_name = '';
  shop_sub_domain = '';
  shop_id = '';
  gold: string = '';
  display_rate!: any;
  show_rate = false;
  rate_time!: any;
  title: any;
  version = environment.version;
  buttonColor: any;
  selectedTabIndex = 0;
  currentItem: any;
  show_mobile_nav!: boolean;
  pageTitle: any;
  open_side_nav!: boolean;
  @ViewChild('drawer', { static: false })
  sideDrawer!: MatSidenav;
  sipPlan!: Rest<SIPPlan>;

  inactivehome!: any;
  activehome!: any;

  inactiveAnalytics!: any;
  activeAnalytics!: any;

  inactiveCatalogue!: any;
  activeCatalogue!: any;

  digiStatus: "NOT_ELIGIBLE" | "NOT_REGISTERED" | "REGISTERED" | undefined = undefined

  constructor(
    private _location: Location,
    private titleService: TitleService,
    private route: ActivatedRoute,
    private rateService: RateServiceService,
    private router: Router,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private elem: ElementRef,
    private authservice: PageAccessService,
    private planService:PlanServiceService,
    private ratecutservice:RateCutService,
    private digiService: DigitalCoinService
  ) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        // Use the current route's data to set the page title
        const title =
          this.router.routerState.snapshot.root.firstChild!.data['title'];
        this.pageTitle = title;
        if (event.urlAfterRedirects === '/dashboard/mobile_rate') {
          const event={index:0}
          this.onTabChange(event);
        }
        else if (event.urlAfterRedirects === '/dashboard/view_order_list_mobile' || event.urlAfterRedirects === '/dashboard/view_order_list_mobile;is_unassigned=1') {
          const event={index:1}
          this.onTabChange(event);
        }
        else if (event.urlAfterRedirects === '/dashboard/mobile_catalogue') {
          const event={index:2}
          this.onTabChange(event);
        }
        else if (event.urlAfterRedirects === '/dashboard/analytics') {
          const event={index:3}
          this.onTabChange(event);
        }
        else if (event.urlAfterRedirects === '/dashboard/view_repair_list_mobile') {
          const event={index:4}
          this.onTabChange(event);
        }
      });
  }
  onTabChange(event: any) {
    this.selectedTabIndex = event.index;
    console.log(this.selectedTabIndex);
    if (this.selectedTabIndex == 4) {
      this.activehome.style.display = 'block';
      this.inactivehome.style.display = 'none';

      this.activeAnalytics.style.display = 'block';
      this.inactiveAnalytics.style.display = 'none';

      this.activeCatalogue.style.display = 'block';
      this.inactiveCatalogue.style.display = 'none';

      this.router.navigate(['/dashboard/view_repair_list_mobile']);
    } else

    if (this.selectedTabIndex == 3) {
      
    
      this.activehome.style.display = 'block';
      this.inactivehome.style.display = 'none';

      this.activeAnalytics.style.display = 'block';
      this.inactiveAnalytics.style.display = 'none';

      this.activeCatalogue.style.display = 'block';
      this.inactiveCatalogue.style.display = 'none';

      this.router.navigate(['/dashboard/analytics']);
    } else
    if (this.selectedTabIndex == 2) {
      this.activehome.style.display = 'block';
      this.inactivehome.style.display = 'none';

      this.activeAnalytics.style.display = 'block';
      this.inactiveAnalytics.style.display = 'none';

      this.activeCatalogue.style.display = 'none';
      this.inactiveCatalogue.style.display = 'block';

      this.router.navigate(['/dashboard/mobile_catalogue']);
    } else if (this.selectedTabIndex == 1) {
      this.activehome.style.display = 'block';
      this.inactivehome.style.display = 'none';

      this.activeAnalytics.style.display = 'none';
      this.inactiveAnalytics.style.display = 'block';

      this.activeCatalogue.style.display = 'block';
      this.inactiveCatalogue.style.display = 'none';

      this.router.navigate(['/dashboard/view_order_list_mobile']);
    } else if (this.selectedTabIndex == 0) {
      this.activehome.style.display = 'none';
      this.inactivehome.style.display = 'block';

      this.activeAnalytics.style.display = 'block';
      this.inactiveAnalytics.style.display = 'none';

      this.activeCatalogue.style.display = 'block';
      this.inactiveCatalogue.style.display = 'none';

      this.router.navigate(['/dashboard/mobile_rate']);
    }
    // Perform any additional logic or actions based on the selected tab
  }
// recheck this funtion
  // this function is to check if the user is jeweller or not
  checkJew() {
    var isadmin = localStorage.getItem('srp');
    if (isadmin == 'true') {
      return true;
    }
    return false;
  }

  ngOnInit(): void {
    this.digiStatus = (localStorage.getItem("digiStatus") ?? undefined) as typeof this.digiStatus  
    const shop_id = localStorage.getItem('shop_id');
    const shop = JSON.stringify({ type: '1', shop_id: shop_id });
    console.log(shop);

    const previousVersion = localStorage.getItem('version');
    if (previousVersion !== environment.version) {
      const flutter = JSON.stringify({ type: '8', cache_cleared: 'false' });
      console.log(flutter);
      localStorage.setItem('version', environment.version);
    }
   
    setTimeout(() => {
      this.inactivehome = document.getElementById('activehome');
      this.activehome = document.getElementById('inactivehome');
      this.inactivehome.style.display = 'block';
      this.activehome.style.display = 'none';

      this.inactiveAnalytics = document.getElementById('inactiveanalytics');
      this.activeAnalytics = document.getElementById('activeanalytics');

      this.inactiveAnalytics.style.display = 'block';
      this.activeAnalytics.style.display = 'none';

      this.inactiveCatalogue = document.getElementById('inactiveCatalogue');
      this.activeCatalogue = document.getElementById('activeCatalogue');

      this.inactiveCatalogue.style.display = 'block';
      this.activeCatalogue.style.display = 'none';
    }, 100);

    this.getRate();
    this.show_mobile_nav = this.screenWidth < 800 ? true : false;
    injectClarity('fyl0j9jhit');
    this.shop_image = localStorage.getItem('shop_image')!;
    this.shop_name = localStorage.getItem('shop_name')!;
    this.shop_id = localStorage.getItem('shop_id')!;
    this.shop_sub_domain = localStorage.getItem('sub_domain')!;

    this.title = localStorage.getItem('title');
  
  }

  setPageTitle() {
    this.titleService.getTitle().subscribe((title) => {
      this.pageTitle = title;
    });
  }

  back() {
    this._location.back();
  }

  adminuse() {
    this.isadmin = !this.isadmin;
    this.router.navigate(['/dashboard/payment']);
  }

  getRate() {
    this.rateService.getLatestRates().subscribe(
      (data: any) => {
        this.rate_time = data.last_updated;
        this.rates = data;

        try {
          if (this.rates.rates.length != 0) {
            this.display_rate = this.rates.rates;
            this.show_rate = true;
          }
        } catch (e) {}
        console.log(data)
      },
      (error: any) => {
        this.toastr.info(
          '',
          'Something went wrong, please do try again later.',
          { positionClass: 'toast-bottom-right' }
        );
      }
    );
  }

  openRatePage() {
    // if(this.pageTitle=="Rate History")
    // {
    //   this.router.navigateByUrl('/', {skipLocationChange: false}).then(()=>
    //   this.router.navigate(['/dashboard/rate/'],{state:{open_rate:true}}));

    // }
      
      this.router.navigate(['/dashboard/rate/'], { state: { opne_rate: true } });


  }

goToAdvance(){
  this.ratecutservice.getRateCut().subscribe((data:any)=>
    {
        
      this.router.navigate(['/dashboard/view_booking'], { state: { opne_rate: true } });

    },
    (error:any)=>
    {
      this.router.navigate(['/dashboard/view_booking'], { state: { opne_rate: true } });

    })
}



  goToPlans(){

    this.planService.getPlans().subscribe(
      (data) => {
 
        this.sipPlan = data;
        if (this.sipPlan.count == 0) {

          this.router.navigate(['/dashboard/jewellery_plans'], { state: { opne_rate: true } });

        } else {

          this.router.navigate(['/dashboard/customer_ledger'], { state: { opne_rate: true } });

        }
      },
      (error: any) => {
        this.toastr.info(
          '',
          'Something went wrong, please do try again later.',
          { positionClass: 'toast-bottom-right' }
        );
      }
    );
  }

  setCurrentItem(item: any) {
    if (this.currentItem === item) {
      this.currentItem = null;
    } else {
      this.currentItem = item;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
  }

  logout() {
    const shop_id = localStorage.getItem('shop_id');
    const logout = JSON.stringify({ type: '10', shop_id: shop_id });
    console.log(logout);
    localStorage.clear();
    location.reload();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setPageTitle();
    }, 1000);

    $(document).ready(() => {
      var classHighlight = 'highlight';
      var $thumbs = $('.thumbnail').click((e: any) => {
        e.preventDefault();
        $thumbs.removeClass(classHighlight);
        $(this).addClass(classHighlight);
      });

      $('ul.padClass > li').click((e: any) => {
        $('ul.padClass > li').removeClass('active');
        $(this).addClass('active');
      });
    });
    let router_cursor =
      this.elem.nativeElement.querySelectorAll('.router_cursor');
    if (router_cursor) {
      router_cursor.forEach(
        (ele: {
          getAttribute(arg0: string): any;
          style: any;
          id(id: string): unknown;
          nativeElement: any;
        }) => {
          if (this.authservice.check2(ele.getAttribute('name'))) {
          } else {
            ele.style.cursor = 'not-allowed';
          }
        }
      );
    }
  }
  renderWidgetInsideWidgetContainer() {
    throw new Error('Method not implemented.');
  }

  homepageHandler() {
    this.isadmin = true;
    if (this.screenWidth > 800) {
      this.router.navigate(['/dashboard/dashboard']);
    } else {
      this.router.navigate(['/dashboard/mobile_rate']);
    }
  }
  openNotification(content: any) {
    this.modalService.open(content, { centered: true });
  }

  // removed this funtion cause it was causing problem in changing rate
  // @HostListener('window:resize', ['$event'])
  // onWindowResize(event: Event): void {
  //   const mobileWidth = 768; // Define the mobile width threshold as needed

  //   // Check if the window width is less than the mobile width threshold
  //   if (window.innerWidth < mobileWidth) {
  //     // Reload the page or perform any other action on resize to mobile width
  //     window.location.reload();
  //   }
  // }
}
