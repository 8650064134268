import { Component, DestroyRef, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HostListener } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ThisReceiver } from '@angular/compiler';
import { formatDate } from '@angular/common';
import { timer, Subscription, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { throwToolbarMixedModesError } from '@angular/material/toolbar';
import { relativeTimeThreshold } from 'moment';
import { HttpErrorResponse } from '@angular/common/http';

import { NewCustomerService } from 'src/app/new-customer/new-customer.service';
import { Customer } from 'src/app/models/customer';
import { ResponseMessage } from 'src/app/models/message';
import { TitleService } from 'src/app/services/title.service';
import { RefAccount } from 'src/app/models/referals';
import { InstallmentData, PaymentOptions, PaymentType } from 'src/app/models/installments';
import { PaymentManager } from 'src/app/manager/payment';
import { BankAccountsList } from 'src/app/models/accounts';
import { RateCutService } from '../ratecut/rate-cut.service';
import { BookingDataSource } from './bookings_dataSource';
import { BookingValidation } from 'src/app/models/ratecut';
import { SuccessBooking } from 'src/app/models/booking';
import { RateServiceService } from 'src/app/rate-page/rate-service.service';
import { RateFinal } from 'src/app/models/rate';
import { InstallmentServiceService } from 'src/app/create_launch_plans/add-installments/installment-service.service';
import { PageAccessService } from 'src/app/services/pageaccess.service';
import { FormControl, Validators } from '@angular/forms';
import { DigitalCoinService } from 'src/app/digital-coin/services/digital-coin.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OtpTemplateComponent } from 'src/app/digital-coin/common/otp-template/otp-template.component';
import { UserDetails } from 'src/app/digital-coin/interfaces/digital-coin.interface';
import { CustomerDetailsPopupComponent } from 'src/app/digital-coin/customer-details-popup/customer-details-popup.component';
import { CustomerDetailsDialogComponent } from 'src/app/templates/customer-details-dialog/customer-details-dialog.component';



@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.css']
})
export class  BookingsComponent implements OnInit {
  @ViewChild('focus_weight') focus_weight!: ElementRef;

  mobileVerified: boolean = false
  mobileNumber: FormControl<string | null> = new FormControl("", [Validators.required, Validators.pattern("[0-9]{10}")])

  private otpPopup: MatDialogRef<OtpTemplateComponent> | undefined = undefined
  userDetails: UserDetails | undefined = undefined
  wrongOtp: Subject<boolean> = new Subject() // TODO wrong otp event
  account_list: any = [];


  private otpReceiveHandler: (otp: string) => void = (otp) => {
    this.digiService.validateOtp(this.mobileNumber.value!, otp).subscribe(
      {
        next: res => {
          if (res.status == 200) {
            this.otpPopup?.close()
            this.mobileNumberVerified()
            this.openCustomerDetails()
          } else { this.wrongOtp.next(true); this.toastService.error(res.body.error.message) }
        },
        error: err => {
          this.wrongOtp.next(true)
          this.toastService.error(err.error.message ?? "Error Verifying OTP")
        }
      }
    )
  }

  disable_option = false;
  count_donw!: Subscription;
  counter = 60; //otp timer (in seconds)
  tick = 1000;
  show_resend = true;
  current_account!: Customer;
  customer_edit: any;
  responseMessage: any = ResponseMessage;
  edit_section = false;
  otp_value: string = '';
  verify_icon_show = false;
  disableFields = false;
  otp_sent = false;
  phone_number = '';
  run = true; //to make timer work properly
  run1 = true;
  state = '';

  setup_id!:any;

  booking_validation!: BookingValidation[];

  booking_weight!:any;
  booking_rate!:any;
  booking_advance!:any;

  search_ref_by!: any;

  ref_user!: RefAccount;
  ref_users: RefAccount[] = [];
  searched_ref!: any;

  success_booking!:SuccessBooking

  Card = 'Card';
  payment_arr: PaymentOptions[] = [
    new PaymentOptions(
      'Cash',
      [
        new PaymentType(
          '',
          '',
          formatDate(new Date(), 'yyyy-MM-dd', 'en-US'),
          '',
          ''
        ),
      ],
      7
    ),
  ];  payment_manager!: PaymentManager;
  installment_data!: InstallmentData;
  currentDate = new Date()
  pos_placeholder = ['Enter Pos'];
  bank_placeholder = ['Enter Bank', 'Enter Bank', 'Enter Bank'];
  //for not sending otp untill 60 sec

  show_front_page:boolean=true;
  show_booking_details:boolean=false;
  show_payment_details!:any;
  isClicked: boolean = false;

  m_planid: any;
  m_name: any;
  m_phone: any;
  m_plan_amount: any;
  m_payment_mode: any;
  m_installment_data: any;
  m_invoice_url: any;

  edit_booking:any;
  booking_id:any;
  can_collect:any;
  advance_percentage:any;
  customer_no!: string;
  above_range_text!: string;
  rates!: RateFinal;
  amount_received: any;
  current_date_validation!: string;

  clicked_booking!:boolean;
  // dataSource!:BookingDataSource;
  allowed_actions: { [key: string]: boolean } = {};
  constructor(
    private router: Router,
    private _location: Location,
    private modalService: NgbModal,
    private newcustomerservice: NewCustomerService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private titleService: TitleService,
    private ratecutservice:RateCutService,
    private rateService: RateServiceService,
    private installmentService: InstallmentServiceService,
    private authservice :PageAccessService,
    private digiService: DigitalCoinService,
    private toastService: ToastrService,
    private destroyed: DestroyRef,
    private matDialog: MatDialog,


  ) {}

  goBackToFirstScreen(): void {
    this.userDetails = undefined;
    this.mobileVerified = false;
    this.mobileNumber.patchValue("")
    this.mobileNumber.removeValidators([Validators.required, Validators.pattern("[0-9]{10}")])
    this.mobileNumber.updateValueAndValidity()
    this.mobileNumber.addValidators([Validators.required, Validators.pattern("[0-9]{10}")])
   
  }
  mobileNumberVerified(): void {
    this.mobileVerified = true
  }
  getBookingInvoice(invoice_url:any)
  {
   return window.open(invoice_url, '_blank');
  }
  sendDataToPayment(){

  }
  openCustomerDetails(): void {
    if (this.userDetails) this.userDetails!.mobile = this.mobileNumber.value!
    else this.userDetails = { mobile: this.mobileNumber.value! } as UserDetails
    this.matDialog.open(CustomerDetailsDialogComponent, {
      width: '80vw',
      panelClass: ["custom-dialog", "rm-padding"],
      data: {
        userDetails: this.userDetails
      }
    }).afterClosed().subscribe((userDetails: UserDetails) => {
      if (userDetails) this.userDetails = userDetails
    })
  }
  removePaymentRow(index: number) {
    if (this.payment_arr.length == 1) {
      var row = new PaymentOptions(
        '',
        [
          new PaymentType(
            '',
            '',
            formatDate(new Date(), 'yyyy-MM-dd', 'en-US'),
            '',
            ''
          ),
        ],
        7
      );
  
      this.payment_arr[index] = row;
      (<HTMLInputElement>(
        document.getElementById('payment_amount_' + (index - 1))
      )).focus();
      return;
    } else {
     
      this.payment_arr.splice(index, 1);
      (<HTMLInputElement>(
        document.getElementById('payment_amount_' + (index - 1))
      )).focus();
    }
  }
 

  verifyOtp(): void {
    if (this.mobileNumber.valid) {
      this.digiService.sendOtp(this.mobileNumber.value!).subscribe(res => {
        if (res.status == 200) {
          this.toastService.success(res.body.message)
          let enterKeyEvent: Subject<string> = new Subject()
          let verifyClickEvent: Subject<string> = new Subject()
          let resendOtpEvent: Subject<void> = new Subject()

          enterKeyEvent.pipe(takeUntilDestroyed(this.destroyed)).subscribe(this.otpReceiveHandler)

          verifyClickEvent.pipe(takeUntilDestroyed(this.destroyed)).subscribe(this.otpReceiveHandler)
         
          resendOtpEvent.pipe(takeUntilDestroyed(this.destroyed)).subscribe(() => {
            this.digiService.sendOtp(this.mobileNumber.value!)
          this.focus('booking_weight')
          })

          this.otpPopup = this.matDialog.open(OtpTemplateComponent, {
            width: "25vw", panelClass: "custom-dialog", data: {
              enterKeyEvent: enterKeyEvent,
              verifyClickEvent: verifyClickEvent,
              resendOtpEvent: resendOtpEvent,
              wrongOtp: this.wrongOtp
            }
          })
          this.focus('booking_weight')
        } else this.toastService.error(res.body.error.message)

      }, err => {
        if (err.status == 400 && err.error.data) {
          this.mobileNumberVerified()
          this.userDetails = err.error.data
          this.focus('booking_weight')

        } else {
          this.toastService.error(err.error.message)
        }
      })
    }
    else{
      this.toastService.error("Please Enter Valid Mobile Number")
    }
  }


  focus(id: string) {
    setTimeout(() => {
      const input = <HTMLInputElement>document.getElementById(id);
      input.focus();
    }, 100);
  }
  ngOnInit(): void {
    this.titleService.setTitle('Bookings');
    this.allowed_actions=this.authservice.allowedActions;
    this.ratecutservice.allowedRateCut().subscribe((data: any) => {
      this.booking_validation = data;
      console.log(this.booking_validation);

    });
    this.getPaymentAccounts();

    this.payment_manager = new PaymentManager();
    this.installment_data = new InstallmentData(
      '',
      formatDate(this.currentDate, 'yyyy-MM-dd', 'en-US'),
      '',
      '',
      '',
      '',
      '',
      '',
      ''
    );

  

    setTimeout(() => {
      (<HTMLInputElement>document.getElementById('phoneno')).focus();
    }, 400);
    this.titleService.setTitle('Bookings');

    if (this.route.snapshot.paramMap.get('id')) {
      this.customer_edit = this.route.snapshot.paramMap.get('id');
      this.editCustomer(this.customer_edit);
    }
    if(this.route.snapshot.queryParamMap.get('edit_booking')=='true')
    {
      this.edit_booking=true
      this.booking_id=this.route.snapshot.queryParamMap.get('id')
      this.booking_weight=this.route.snapshot.queryParamMap.get('weight')
      this.booking_rate=this.route.snapshot.queryParamMap.get('rate')
      this.booking_advance=this.route.snapshot.queryParamMap.get('advance')
      this.show_front_page=false;

      this.show_booking_details=true;
      console.log(this.booking_advance)
      this.mobileVerified=true;


    }
    // this.dataSource= new BookingDataSource (this.ratecutservice)
    // this.dataSource.loadData(0,'','','');
    // console.log(this.dataSource)
    this.getRate();
    this.current_date_validation=this.getCurrentDate()
  }
  getPaymentAccounts() {
    this.installmentService.getPayments().subscribe((data) => {
      for (let i of data.bank_accounts) {
        this.account_list.push(i);
      }
      for (let i of data.pos_accounts) {
        this.account_list.push(i);
      }
    });
  }

  getRate() {
    this.rateService.getLatestRates().subscribe(
      (data: any) => {

        this.rates = data;
        console.log(this.rates)


      },
      (error: any) => {
        this.toastr.info(
          '',
          'Something went wrong, please do try again later.',
          { positionClass: 'toast-bottom-right' }
        );
      }
    );
  }
  getCurrentDate(): string {
    const today = new Date();
    let day: string = String(today.getDate());
    let month: string = String(today.getMonth() + 1);
    const year: string = String(today.getFullYear());

    if (day.length < 2) {
      day = '0' + day;
    }

    if (month.length < 2) {
      month = '0' + month;
    }

    return `${year}-${month}-${day}`;
  }

  isAboveRange()
  {

    for (let i = 0; i < this.booking_validation.length; i++) {

      if (
        (Number(this.booking_weight)) >  Number(this.booking_validation[i].weight_to)) {
          this.above_range_text="Kindly enter the weight within your setup range"

      }
      else
      {
        this.above_range_text=""
        this.suggestionRate()

      }
    }

  }

  suggestionRate()
  {

    for (let i = 0; i < this.booking_validation.length; i++) {
      // console.log(this.booking_validation[i])
      if (
        Number(this.booking_weight) >= Number(this.booking_validation[i].weight_from) &&
       Number (this.booking_weight) <= Number(this.booking_validation[i].weight_to)) {
        this.advance_percentage = this.booking_validation[i].advance_percentage;
        this.can_collect=(this.booking_rate*this.booking_weight*(this.advance_percentage/100)).toFixed(3);
          this.setup_id=this.booking_validation[i].id
          this.booking_advance=Math.round(Number(this.can_collect))

      }
    }
  }


  editBooking()
  {
    var data=
    {
      id:this.booking_id,
      weight:this.booking_weight,
      rate:this.booking_rate,
      advance:this.booking_advance
    }
    this.ratecutservice.editBooking(data).subscribe((data:any)=>
    {
      this.router.navigate(['/dashboard/view_bookings']);
      this.toastr.success('', data.message, {
        positionClass: 'toast-bottom-right',
      });
    },(error:any)=>
    {

      this.toastr.info(
        '',
        error.error.message,
        { positionClass: 'toast-bottom-right' }
      );
    })
  }
  openPaymentModel(content: any) {
    {
      this.modalService.open(content, {
        centered: true,
        size: 'xl',
        backdrop: 'static',
        modalDialogClass: 'pm',
      });
      setTimeout(() => {
        (<HTMLInputElement>document.getElementById('payment_amount_0')).focus();
      }, 100);
    
      return;
    }
  }
  returnFun() {
    this.Card = '';
  }
  onSelect(event: any, ind: any) {
    if (this.pos_placeholder[ind]) {
      this.pos_placeholder[ind] = '';
    } else {
      this.bank_placeholder[ind] = '';
    }
  }

  addOneMoreRecord(payment_type: string) {
    for (var i = 0; i < this.payment_arr.length; i++) {
      if (this.payment_arr[i].type == payment_type) {
        var payment_new_row = new PaymentType(
          '',
          '',
          formatDate(this.currentDate, 'yyyy-MM-dd', 'en-US'),
          '',
          ''
        );
        this.payment_arr[i].options.push(payment_new_row);

        break;
      }
    }
  }

  ngOnDestroy(): void {}

  showContent(content: any) {
    this.modalService.open(content, { centered: true });
  }
  back() {
    if (this.edit_booking)
    {
      this.router.navigate(['/dashboard/view_bookings']);
    }
    if(this.show_front_page)
    {
      console.log(this.current_account)
      this._location.back();

    }
    else if(this.show_booking_details)
    {

      this.show_booking_details=false;
      this.show_front_page=true;

      console.log(this.customer_no)
      setTimeout(() => {
        (<HTMLInputElement>document.getElementById('fullname')).value =
        this.current_account.first_name;

      (<HTMLInputElement>document.getElementById('phoneno')).value =
      this.customer_no;
      // (<HTMLInputElement>(document.getElementById('plan_material'))).value=this.currentPlan.material.toString();

      (<HTMLInputElement>document.getElementById('dob')).value =
        this.current_account.dob;
      (<HTMLInputElement>document.getElementById('email')).value =
      this.current_account.email;
      (<HTMLInputElement>document.getElementById('gender')).value =
      this.current_account.gender;

      (<HTMLInputElement>document.getElementById('address')).value =
      this.current_account.address;
      (<HTMLInputElement>document.getElementById('pincode')).value =
      this.current_account.pincode;


        (<HTMLInputElement>document.getElementById('state')).value =
        this.current_account.state;
      }, 1);
    }
    else if (this.show_payment_details)
    {

      this.show_booking_details=true;
      this.show_payment_details=false;

    }


  }





  onKeyDown(event: KeyboardEvent) {
    const key = event.key;
    const isAlphabeticOrSpace = /^[a-zA-Z\s]$/.test(key);
    const isArrowKey = key.includes('Arrow');
    const isBackspace = key === 'Backspace';
    const isTabKey = key === 'Tab'; // Added condition for the Tab key
    
    if (!isAlphabeticOrSpace && !isArrowKey && !isBackspace && !isTabKey) {
      event.preventDefault();
    }
  }



  showSecondScreen()
  {
    this.show_booking_details=true;
    this.show_front_page=false;

    this.booking_rate=Number(this.rates.rates[1].rate)

    setTimeout(() => {
      this.focus_weight.nativeElement.focus();
    }, 10);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);

  }

  showThirdScreen()
  {

    if ((!this.booking_advance) || (!this.booking_rate) || (!this.booking_weight))
    {
      this.toastr.info(
        '',
        'Please Enter Valid Input',
        { positionClass: 'toast-bottom-right' }
      );
    }
    else{
      
      // this.show_payment_details=true;
      // this.show_booking_details=false;
      // this.show_front_page=false;
      // window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
  preventDefault(event: any) {
    event.preventDefault();
  }
  addPaymentRow(index: number) {
    var row = new PaymentOptions(
      '',
      [
        new PaymentType(
          '',
          '',
          formatDate(new Date(), 'yyyy-MM-dd', 'en-US'),
          '',
          ''
        ),
      ],
      7
    );
    if (
      JSON.stringify(this.payment_arr[this.payment_arr.length - 1]) ==
      JSON.stringify(row)
    ) {
      this.toastr.info('', 'Please Fill the Required Fields', {
        positionClass: 'toast-bottom-right',
      });
      return;
    }
    this.payment_arr.push(row);
    setTimeout(() => {
      const element = <HTMLDivElement>document.getElementById('pm_first');
      // payment_block?.scrollIntoView({behavior:'smooth'})
      if (element) {
        // element.scrollTop = element.scrollHeight;
        element.scrollTop = element.scrollHeight;
      }
      (<HTMLInputElement>(
        document.getElementById('payment_amount_' + (index + 1))
      )).focus();
    }, 100);
  }

  timerFun() {
    if (this.run == true) {
      this.count_donw = timer(0, this.tick)
        .pipe(take(this.counter))
        .subscribe(() => {
          --this.counter;

          if (this.counter == 0) {
            this.count_donw.unsubscribe();
          }
        });
    }
    this.run = false;
  }

  setCounter() {
    this.counter = 60;
  }

  getStateFromPincode() {
    var pincode = (<HTMLInputElement>document.getElementById('pincode')).value;
    this.newcustomerservice.getState(pincode).subscribe((data: any) => {
      this.state = data.state;
    }),
      (error: any) => {
        this.toastr.info(
          '',
          'Something went wrong, please do try again later.',
          { positionClass: 'toast-bottom-right' }
        );
      };
  }

  editCustomer(id: string) {
    this.disable_option = true;
    this.disableFields = true;
    this.edit_section = true;
    this.newcustomerservice.getUserbyId(id).subscribe((data) => {
      this.current_account = data;

      (<HTMLInputElement>document.getElementById('fullname')).value =
        this.current_account.first_name;

      (<HTMLInputElement>document.getElementById('phoneno')).value =
        this.current_account.phone;
      // (<HTMLInputElement>(document.getElementById('plan_material'))).value=this.currentPlan.material.toString();

      (<HTMLInputElement>document.getElementById('dob')).value =
        this.current_account.dob;
      (<HTMLInputElement>document.getElementById('email')).value =
        this.current_account.email;
      (<HTMLInputElement>document.getElementById('gender')).value =
        this.current_account.gender;

      (<HTMLInputElement>document.getElementById('address')).value =
        this.current_account.address;
      (<HTMLInputElement>document.getElementById('pincode')).value =
        this.current_account.pincode;

      (<HTMLInputElement>document.getElementById('state')).value =
        this.current_account.state;
    });
  }

  createUser() {

    var phoneno = (<HTMLInputElement>document.getElementById('phoneno')).value;
    var fullname = (<HTMLInputElement>document.getElementById('fullname'))
      .value;

    var dob = (<HTMLInputElement>document.getElementById('dob')).value;
    var gender = (<HTMLInputElement>document.getElementById('gender')).value;
    var email = (<HTMLInputElement>document.getElementById('email')).value;
    var pincode = (<HTMLInputElement>document.getElementById('pincode')).value;
    var address = (<HTMLInputElement>document.getElementById('address')).value;
    var state = (<HTMLSelectElement>document.getElementById('state')).value;

    if (fullname.trim().length === 0) {
      this.toastr.info('', 'Invalid Name', {
        positionClass: 'toast-bottom-right',
      });
      return;
    } else if (address.trim().length === 0) {
      this.toastr.info('', 'Invalid Address', {
        positionClass: 'toast-bottom-right',
      });
      return;
    } else if (state == '' || state == 'Choose State') {
      this.toastr.info('', 'Select State', {
        positionClass: 'toast-bottom-right',
      });
      return;
    }

    this.newcustomerservice
      .postUser(
        fullname,
        phoneno,
        dob,
        email,
        gender,
        address,
        pincode,
        state,
        this.searched_ref
      )
      .subscribe(
        (data) => {
          this.responseMessage = data;
          this.toastr.success('', 'Account Created Successfully', {
            positionClass: 'toast-bottom-right',
          });
          this.showSecondScreen()

        },
        (error: any) => {
          this.toastr.info(
            '',
            'Something went wrong, please do try again later.',
            { positionClass: 'toast-bottom-right' }
          );
        }
      );

  }

  commonFunction() {
    if (this.edit_section) {
      this.editUser();
    } else {
      this.createUser();
    }
  }

  editUser() {
    var fullname = (<HTMLInputElement>document.getElementById('fullname'))
      .value;

    var dob = (<HTMLInputElement>document.getElementById('dob')).value;
    var gender = (<HTMLInputElement>document.getElementById('gender')).value;
    var email = (<HTMLInputElement>document.getElementById('email')).value;
    var pincode = (<HTMLInputElement>document.getElementById('pincode')).value;
    var address = (<HTMLInputElement>document.getElementById('address')).value;
    var state = (<HTMLInputElement>document.getElementById('state')).value;


    if (fullname.trim().length === 0) {
      this.toastr.info('', 'Invalid Name', {
        positionClass: 'toast-bottom-right',
      });
      return;
    } else if (address.trim().length === 0) {
      this.toastr.info('', 'Invalid Address', {
        positionClass: 'toast-bottom-right',
      });
      return;
    } else if (state == '' || state == 'Choose State') {
      this.toastr.info('', 'Select State', {
        positionClass: 'toast-bottom-right',
      });
      return;
    }



    this.newcustomerservice
      .patchUserbyId(
        this.customer_edit,
        fullname,
        dob,
        email,
        gender,
        address,
        pincode,
        state,
        '',
        ''
      )
      .subscribe(
        (data) => {
          this.responseMessage = data;
          this.toastr.success('', 'Account Edited Successfully', {
            positionClass: 'toast-bottom-right',
          });
          this.showSecondScreen()

        },
        (error: any) => {
          this.toastr.info(
            '',
            'Something went wrong, please do try again later.',
            { positionClass: 'toast-bottom-right' }
          );
        }

      );
  }

  onOtpChange(value: any) {
    if (value.length == 4) {
      this.otp_value = value;
    }
  }



  getOtpNumber(content: any) {
    var mobile = (<HTMLInputElement>document.getElementById('phoneno')).value;
    if (mobile.length==11 && (mobile[0]=='0') )
    {
      this.customer_no=mobile.slice(1)
      mobile=mobile.slice(1)

    }
    if (mobile.length == 10) {
      if (mobile.length == 10 && mobile !== this.phone_number) {


        this.phone_number = mobile;

        this.newcustomerservice.sendOtp(mobile).subscribe(
          (data) => {
            this.responseMessage = data;

            this.toastr.success('', this.responseMessage.message, {
              positionClass: 'toast-bottom-right',
            });
            this.otp_sent = true;
            this.modalService.open(content, { centered: true });
          },

          (err: HttpErrorResponse) => {

            this.modalService.dismissAll(content);
            this.run1 == true;
            this.responseMessage = err;
            this.current_account=this.responseMessage.error.data
            this.customer_edit = this.responseMessage.error.data.id;

            this.disable_option = true;
            this.verify_icon_show = true;
            this.disableFields = true;
            this.toastr.info('', this.responseMessage.error.message, {
              positionClass: 'toast-bottom-right',
            });
            if (this.responseMessage.status == 400) {
              (<HTMLInputElement>document.getElementById('fullname')).value =
                this.responseMessage.error.data.first_name;

              (<HTMLInputElement>document.getElementById('dob')).value =
                this.responseMessage.error.data.dob;
              (<HTMLInputElement>document.getElementById('email')).value =
                this.responseMessage.error.data.email;
              (<HTMLInputElement>document.getElementById('gender')).value =
                this.responseMessage.error.data.gender;

              (<HTMLInputElement>document.getElementById('address')).value =
                this.responseMessage.error.data.address;
              (<HTMLInputElement>document.getElementById('pincode')).value =
                this.responseMessage.error.data.pincode;

              (<HTMLInputElement>document.getElementById('state')).value =
                this.responseMessage.error.data.state;
            }
          }
        );
      } else {
        this.modalService.open(content, { centered: true });
        this.toastr.info('', `Resend After ${this.counter} sec`, {
          positionClass: 'toast-bottom-right',
        });
      }
    } else {
      this.modalService.dismissAll(content);
      this.toastr.info('', 'Please Enter 10 Digit Number', {
        positionClass: 'toast-bottom-right',
      });
    }
  }

  searchReferredBy(event: any) {
    var data = event.target.value;

    if (data.length > 2) {
      this.newcustomerservice.searchUser(data).subscribe((data: any) => {
        this.ref_users = data.reponse;
      });
    }
  }

  getOtpNumberValidate() {
    var mobile = (<HTMLInputElement>document.getElementById('phoneno')).value;
    if (mobile.length == 10) {
      var otp = this.otp_value;

      this.newcustomerservice.validateOtp(mobile, otp).subscribe(
        (data) => {
          this.responseMessage = data;
          this.toastr.success('', this.responseMessage.message, {
            positionClass: 'toast-bottom-right',
          });
          this.disable_option = true;
          this.verify_icon_show = true;
          this.disableFields = true;
        },
        (error: any) => {
          this.toastr.info('', 'Wrong OTP.', {
            positionClass: 'toast-bottom-right',
          });
        }
      );
    }
  }


  resendOtp(type: any) {
    var mobile = (<HTMLInputElement>document.getElementById('phoneno')).value;

    var otp = this.otp_value;
    var fd = new FormData();
    fd.append('mobile', mobile);

    fd.append('voice', type);
    this.newcustomerservice.resendOtp(fd).subscribe(
      (data) => {
        this.responseMessage = data;
        this.toastr.success('', this.responseMessage.message, {
          positionClass: 'toast-bottom-right',
        });
      },
      (error: any) => {
        this.toastr.info('', 'Wrong OTP.', {
          positionClass: 'toast-bottom-right',
        });
      }
    );
  }
  amountValidation()
  {
    this.amount_received=0;
    for(let i of this.payment_arr)
    {
      for (let j of i.options)
      {
        this.amount_received+=j.amount
      }
    }
    if (this.booking_advance==this.amount_received)
    {
      return true
    }
    else{
      return false
    }
  }

  addBooking(content:any)
  {

    // if (!this.amountValidation)
    // {
    //   return
    // }
    this.clicked_booking=true
    var data=
    {
      setup_id:this.setup_id,
      phone:this.mobileNumber.value,
      payment_options:this.payment_arr,
      weight:this.booking_weight,
      rate:this.booking_rate,
      payable:this.booking_advance
    }


    this.ratecutservice.addBooking(data).subscribe(
      (data:any)=>
      {
        this.success_booking=data.data
        this.router.navigate(['/dashboard/view_bookings']);
        this.toastr.success('', data.message, {
          positionClass: 'toast-bottom-right',
        });
        this.modalService.dismissAll();

        this.modalService.open(content, { centered: true });
      },(error:any)=>
      {

        this.toastr.info(
          '',
          error.error.message,
          { positionClass: 'toast-bottom-right' }
        );
        this.clicked_booking=false
      }
    )
  }

  @HostListener('window:keydown.shift.enter', ['$event'])
  rowHandler() {
    this.addOneMoreRecord(this.Card);
  }
  @HostListener('focus', ['$event.target'])
  onFocus(target: HTMLInputElement) {
    target.select();
  }





}
