import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { InterceptorInterceptor } from '../services/interceptor.interceptor';
import { LoginService } from './login.service';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AnalyticService } from '../services/analytics.service';
import { timer, Subscription, of } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, elementAt, map, switchMap, take } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgOtpInputModule } from 'ng-otp-input';
import { HttpResponse } from '@angular/common/http';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  @ViewChild("shopinfo")
  shopSuccess!: TemplateRef<any>;
  shop_type:string="0";
  counter_store_id!: any;
  counter_id!: any;
  counter_pin!: any;
  counter_otp!: any;
  screenWidth!: number;
  countDown!: Subscription;
  counter = 60; //otp timer (in seconds)
  tick = 1000;
  ShowResend = true;
  run = true; //to make timer work properly
  run1 = true;

  create_shop_button:boolean=true;
  admin_mobile!: any;

  show_jeweler_login = true;
  show_counter_login = false;

  show_otp_box = false;
  show_shop_name=false;
  show_login_button = false;
  show_otp_spinner = false;
  show_login_spinner = false;
  show_otp_button = true;
  show_resend_otp = false;
  show_countdown=false;
  show_register_button=false;
  show_register_spinner=false;
  display_shop_id="";
  countdown!:NodeJS.Timeout;
  show_proceed_button = false;
  
  mobile_number: string = '';
  shop_id: string = '';
  shop_name="";
  otp_value: string = '';
  resend_count = 0;
  send_otp_button: any;
  send_otp_button_reg:any;
  count_down_p: any;
  count_down_r:any;
  show_timer: any;
  logged_in_message = 'Logged in successfully';

  isMobile!: boolean;
  isDesktop!: boolean;

  constructor(
    private loginService: LoginService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private analytics: AnalyticService,
    private modalService: NgbModal,
    private deviceService: DeviceDetectorService,

  ) {
    this.screenWidth = window.innerWidth;
    this.isMobile = this.deviceService.isMobile();
    this.isDesktop = this.deviceService.isDesktop();
    if(localStorage.getItem("jwt_refresh_token"))
    {
      this.router.navigate(['/dashboard/dashboard']);
    }


  }

  sendEvent(str:string)
  {
    this.analytics.logEvent("Login Page Loaded", {})
  }

  ngOnInit(): void {
    this.getElementsById();
    this.setupScreen();
    setTimeout(() => {
      var new1 = <HTMLInputElement>document.getElementById('count_login');
      new1.style.display = 'none';
      var new3 = <HTMLInputElement>document.getElementById('jew_register');
      new3.style.display = 'none';
    }, 1);


    // if (this.proute.data['signup']==true) {
    //   this.showRegister()
    // }
    setTimeout(() => {
      console.log(localStorage.getItem('signup'))
      if(localStorage.getItem("signup")=="true")
      {
        console.log("comming here")
        this.showRegister()
      }
    }, 100);


  }


  getElementsById() {
    this.send_otp_button = <HTMLInputElement>(
      document.getElementById('send_otp_btn')
    );
    this.count_down_p = <HTMLInputElement>document.getElementById('countdown');

    this.show_timer = <HTMLInputElement>(
      document.getElementById('send_otp_label')
    );
  }
  alreadyAccount(){
    var new2 = <HTMLInputElement>document.getElementById('jew_login');
    new2.style.display = 'block';
    var new1 = <HTMLInputElement>document.getElementById('count_login');
    new1.style.display = 'none';
    var new3 = <HTMLInputElement>document.getElementById('jew_register');
    new3.style.display = 'none';

    var new4 = <HTMLElement>document.getElementById('myTab');
    new4.style.display = 'flex';
    this.show_jeweler_login = false;
    this.show_counter_login = false;
    this.show_otp_box = false;
    this.show_resend_otp = false;
    this.mobile_number='';
    this.focus('shop_id');
    clearInterval(this.countdown);
    this.setCounter()
    this.send_otp_button = <HTMLInputElement>(
      document.getElementById('send_otp_btn')
    );
    this.show_login_button=false;
    setTimeout(() => {
      this.show_timer.style.display='none';
    }, 100);
    // this.send_otp_button_reg.style.display='block';
    // this.send_otp_button.disabled=false;
    this.send_otp_button.disabled=true;
    this.send_otp_button.style.display='block';
  }
  focus(id:string)
  {
    setTimeout(() => {
      var new1 = <HTMLInputElement>document.getElementById(id);
      new1.focus();
    }, 100);

  }
  // on register phone change

  showJeweler() {
    var new2 = <HTMLInputElement>document.getElementById('jew_login');
    new2.style.display = 'block';
    var new1 = <HTMLInputElement>document.getElementById('count_login');
    new1.style.display = 'none';
    var new3 = <HTMLInputElement>document.getElementById('jew_register');
    new3.style.display = 'none';

    var new4 = <HTMLElement>document.getElementById('myTab');
    new4.style.display = 'flex';
    this.show_jeweler_login = true;
    this.show_counter_login = false;
  }
  showCounter() {
    var new2 = <HTMLInputElement>document.getElementById('jew_login');
    new2.style.display = 'none';
    var new1 = <HTMLInputElement>document.getElementById('count_login');
    new1.style.display = 'block';
    var new3 = <HTMLInputElement>document.getElementById('jew_register');
    new3.style.display = 'none';
    this.show_counter_login = true;
    this.show_jeweler_login = false;
  }

  showRegister() {
    var new2 = <HTMLInputElement>document.getElementById('jew_login');
    new2.style.display = 'none';
    var new1 = <HTMLInputElement>document.getElementById('count_login');
    new1.style.display = 'none';
    var new3 = <HTMLInputElement>document.getElementById('jew_register');
    new3.style.display = 'block';
    // var otp_label = <HTMLInputElement>document.getElementById("send_otp_label_reg");
    this.send_otp_button_reg = <HTMLElement>document.getElementById("send_otp_btn_reg");
    // otp_label.style.display='none';

    var new4 = <HTMLElement>document.getElementById('myTab');
    new4.style.display = 'none';

    this.show_counter_login = true;
    this.show_jeweler_login = false;
    this.send_otp_button = <HTMLInputElement>(
      document.getElementById('send_otp_btn_reg')
    );
    this.show_otp_box = false;
    this.show_resend_otp = false;
    this.mobile_number='';
    clearInterval(this.countdown);
    this.setCounter()
  }


  setupScreen() {
    this.show_timer.style.display = 'none';
  }

  onOtpChangeCounter(value: any) {
    if (value.length == 4) {
      this.counter_otp = value;
    }
  }

  onPinChangeCounter(value: any) {
    if (value.length == 4) {
      this.counter_pin = value;
    }
  }

  onOtpChange(value: any) {
   if(!isNaN(Number(value)))
   {
    if (value.length == 4) {
      this.show_login_button = true;
      this.otp_value = value;
    }}
  }





  onPhoneChange(event: any) {
    this.mobile_number = event.target.value;
    console.log(this.mobile_number)
    if (this.mobile_number.length==11 && this.mobile_number[0]=='0')
    {
      this.mobile_number=this.mobile_number.slice(1)
    }
    if (this.mobile_number.length == 10) {
      this.send_otp_button.disabled = false;

    } else {
      this.send_otp_button.disabled = true;
    }
  }
  sendOtp() {
    if (this.mobile_number == '' || this.shop_id == '') {
      this.toastr.info('', 'Invalid mobile number or shop id', {
        positionClass: 'toast-bottom-right',
      });
    } else {
      // this.send_otp_button_reg.disabled = true;
      this.show_otp_spinner = true;
      

      this.loginService.sendOtp(this.mobile_number, this.shop_id).subscribe(
        (data: any) => {
          this.show_otp_spinner = false;
          this.show_otp_box = true;
          this.show_otp_button = false;
          this.show_timer.style.display = 'block';
          this.send_otp_button.style.display = 'none';

          let seconds: any = 60;
          let countdown = setInterval(() => {
            seconds--;
            this.count_down_p.innerHTML = seconds;
            if (seconds <= 0) {
              clearInterval(countdown);
              this.show_timer.style.display = 'none';
              this.show_resend_otp = true;
              this.show_otp_box = false;
            }
          }, 1000);
        },
        (error: any) => {
          this.toastr.info(
            '',
            'Invalid phone or shop Id, Please do check again.',
            { positionClass: 'toast-bottom-right' }
          );
          this.show_otp_spinner = false;
          this.send_otp_button.disabled = false;
          this.show_register_button=false;
        }
      );
    }
    //<div id="a"></div>
  }



  sendOtpRegister() {
    if (this.mobile_number == '') {
      this.toastr.info('', 'Invalid mobile number', {
        positionClass: 'toast-bottom-right',
      });
    } else {
      this.show_otp_spinner = true;
      this.show_countdown=true;


      this.loginService.sendRegisterOtp(this.mobile_number).subscribe(
        (data: any) => {

      this.count_down_r = <HTMLElement>document.getElementById('countdown_reg');
      console.log(this.count_down_r);
          this.show_otp_spinner = false;
          this.show_otp_box = true;
          this.show_shop_name=false;
          this.show_otp_button = false;
          // this.show_register_button=true;
          this.show_proceed_button=true;
          this.show_timer.style.display = 'block';
          this.send_otp_button_reg.style.display = 'none';

          let seconds: any = 60;
          this.countdown = setInterval(() => {
            seconds--;
            this.count_down_r.innerHTML = seconds;
            if (seconds <= 0) {
              clearInterval(this.countdown);
              this.show_timer.style.display = 'none';
              (<HTMLDivElement>document.getElementById('mobile_block')).style.display='block';
              this.show_resend_otp = true;
              this.show_otp_box = true;
              this.show_shop_name=false;
              this.show_register_button=false;
              this.show_countdown=false;
              this.show_proceed_button=false;
              this.send_otp_button_reg.style.display = 'none';
            }
          }, 1000);
          // var new1 =(<HTMLElement>document.getElementById('already_account'));
          //   new1.style.display='none';
        },
        (error: any) => {
          this.toastr.info(
            '',
            'Invalid phone or shop Id, Please do check again.',
            { positionClass: 'toast-bottom-right' }
          );
          this.show_otp_spinner = false;
          this.send_otp_button.disabled = false;
          this.send_otp_button_reg.style.display = 'block';

        }
      );
    }
    //<div id="a"></div>
  }


  proceedClick()
  {

    this.loginService.verifyOtp(this.mobile_number, this.otp_value).subscribe((data:any)=>
    {
      this.toastr.success('',data.message,{positionClass:'toast-bottom-right'});
      this.show_shop_name=true;
      this.show_otp_box=false;
      this.show_proceed_button=false;
      (<HTMLDivElement>document.getElementById('mobile_block')).style.display='none';
      clearInterval(this.countdown);
      this.show_countdown=false;
    },
    (error:any)=>
    {
      this.toastr.info('',error.error.message,{positionClass:'toast-bottom-right'});
    })
  }


  resendOtp() {
    this.send_otp_button.disabled = true;
    this.show_otp_spinner = true;
    let fd = new FormData();
    fd.append('mobile', this.mobile_number);
    fd.append('shop_id', this.shop_id);
    if (this.resend_count == 1) {
      fd.append('voice', 'true');
    }

    this.loginService.resendOtp(fd).subscribe(
      (data: any) => {
        this.resend_count = 1;
        this.show_resend_otp = false;
        this.show_otp_spinner = false;
        this.show_otp_box = true;
        this.show_otp_button = false;
        this.send_otp_button_reg.style.display = 'none';
        this.show_timer.style.display = 'block';
        let seconds: any = 60;
        this.countdown = setInterval(() => {
          seconds--;
          this.count_down_p.innerHTML = seconds;
          if (seconds <= 0) {
            clearInterval(this.countdown);
            this.show_timer.style.display = 'none';
            this.show_otp_box = false;

            this.show_resend_otp = true;
            this.send_otp_button_reg.style.display = 'block';

          }
        }, 1000);
      },
      (error: any) => {
        this.toastr.info(
          '',
          'Invalid phone or shop Id, Please do check again.',
          { positionClass: 'toast-bottom-right' }
        );
        this.show_otp_spinner = false;
        this.send_otp_button.disabled = false;
        this.send_otp_button_reg.style.display = 'block';

      }
    );
  }


  resendOtpRegister() {
    this.show_otp_spinner = true;
    let fd = new FormData();
    fd.append('mobile', this.mobile_number);
    if (this.resend_count == 1) {
      fd.append('voice', 'true');
    }
    this.show_countdown=true;
    setTimeout(() => {
      this.count_down_r = <HTMLElement>document.getElementById('countdown_reg');
    }, 100);


    this.loginService.resendRegisterOtp(fd).subscribe(
      (data: any) => {
        this.resend_count = 1;
        this.show_countdown=true;
        this.show_resend_otp = false;
        this.show_otp_spinner = false;
        this.show_otp_box = true;
        this.show_shop_name=false;
        this.show_register_button=false;
        this.show_proceed_button=true;
        this.show_otp_button = false;
        this.send_otp_button.style.display = 'none';
        this.show_timer.style.display = 'block';
        let seconds: any = 60;
        let countdown = setInterval(() => {
          seconds--;
          console.log(seconds)
          this.count_down_r.innerHTML = seconds;
          if (seconds <= 0) {
            clearInterval(countdown);
            this.show_timer.style.display = 'none';
            (<HTMLDivElement>document.getElementById('mobile_block')).style.display='block';
            this.show_resend_otp = true;
            this.show_otp_box = true;
            this.show_countdown=false;
            this.show_shop_name=false;
            this.show_proceed_button=false;
            this.show_register_button=false;
            this.send_otp_button_reg.style.display='none'
          }
        }, 1000);
      },
      (error: any) => {
        this.toastr.info(
          '',
          'Invalid phone or shop Id, Please do check again.',
          { positionClass: 'toast-bottom-right' }
        );
        this.show_otp_spinner = false;
        this.send_otp_button.disabled = false;
        this.show_register_button=false;

      }
    );
  }

  login() {
    this.show_login_spinner = true;

    this.loginService
      .login(this.mobile_number, this.otp_value, this.shop_id)
      .pipe(switchMap((res1: any) => {
        localStorage.setItem(
          InterceptorInterceptor.accessToken,
          res1['data']['access']
        );
        localStorage.setItem(
          InterceptorInterceptor.refreshToken,
          res1['data']['refresh']
        );
        ; return this.loginService.getDGStatus().pipe(map(res2 => [res1, res2]), catchError(err => of([res1, err])))
      }))
      .subscribe(
        (response: any[]) => {
          let data = response[0]
          let digiRes = (response[1] as HttpResponse<any>)
          // "NOT_ELIGIBLE" | "NOT_REGISTERED" | "REGISTERED"
          if (digiRes.status == 200) {
            localStorage.setItem("digiStatus", "REGISTERED")
          } else if (digiRes.status == 404) {
            localStorage.setItem("digiStatus", "NOT_REGISTERED")
          } else {
            localStorage.setItem("digiStatus", "NOT_ELIGIBLE")
          }
          
          console.log(data);
          
          localStorage.setItem('shop_image', data['shop_image']);
          localStorage.setItem('shop_name', data['shop_name']);
          localStorage.setItem('shop_id', data['shop_id']);
          localStorage.setItem('sub_domain', data['sub_domain']);

          localStorage.setItem('pages', data.pages);
          localStorage.setItem('actions', data.actions);
          // hardcode
          if(data.pages=='admin' && data.actions=='admin')
          {
            localStorage.setItem('roles', 'true');
          }
          else{
            localStorage.setItem('roles', 'false');
          }

          localStorage.setItem('srp','true');
          localStorage.setItem('whatsapp_number', data['whatsapp_number']);

          this.analytics.setUserId(data['shop_id']+"/"+data['shop_name']);

          // localStorage.setItem("type",data['type']);
          this.show_login_spinner = false;

          localStorage.setItem('logged_in', 'true');

          if (data['is_vendor'])
          {
            this.router.navigate(['/dashboard/view_order']);
            return
          }
          if (this.isDesktop)
          {
            this.router.navigate(['/dashboard/dashboard']);
          }
         else if(this.isMobile)
         {

          this.router.navigate(['/dashboard/mobile_rate']);
          }

        },
        (error: any) => {
          this.show_login_spinner = false;

          this.toastr.info(
            '',
            'Invalid phone or shop Id, Please do check again.',
            { positionClass: 'toast-bottom-right' }
          );
        }
      );
  }





  createShop() {
    this.show_login_spinner = true;
    this.create_shop_button=false;
    this.loginService
      .createShop(this.mobile_number, this.otp_value, this.shop_name,this.shop_type)
      .subscribe(
        (data: any) => {
          console.log(data);
          this.display_shop_id = data['shop_id'];
          const modalRef = this.modalService.open(this.shopSuccess);

          this.toastr.success(
            '',
            'Shop created successfully, check your watsapp to login to the shop.',
            { positionClass: 'toast-bottom-right' }
          );

        },
        (error: any) => {
          this.show_login_spinner = false;
          this.create_shop_button=true;
          this.toastr.info(
            '',
            'Invalid phone or shop name, Please do check again.',
            { positionClass: 'toast-bottom-right' }
          );
        }
      );
  }


  counterLogin() {
    if (
      this.counter_id &&
      this.counter_store_id &&
      this.counter_pin &&
      this.counter_otp
    ) {
      this.loginService
        .counterLogin(
          this.counter_id,
          this.counter_store_id,
          this.counter_pin,
          this.counter_otp
        )
        .pipe(switchMap((res1:any) => {
          localStorage.setItem(
            InterceptorInterceptor.accessToken,
            res1['data']['access']
          );
          localStorage.setItem(
            InterceptorInterceptor.refreshToken,
            res1['data']['refresh']
          ); return this.loginService.getDGStatus().pipe(map(res2 => [res1, res2]), catchError(err => of([res1, err])))
        }))
        .subscribe(
          (response: any[]) => {
            let data = response[0]
            let digiRes = (response[1] as HttpResponse<any>)
            // "NOT_ELIGIBLE" | "NOT_REGISTERED" | "REGISTERED"
            if (digiRes.status == 200) {
              localStorage.setItem("digiStatus", "REGISTERED")
            } else if (digiRes.status == 404) {
              localStorage.setItem("digiStatus", "NOT_REGISTERED")
            } else {
              localStorage.setItem("digiStatus", "NOT_ELIGIBLE")
            }
            // this.analysis.logEvent("Counter OTP Validation Success");
            // this.responseMessage = data;
            // this.toastr.success('', this.responseMessage.message, {
            //   positionClass: 'toast-bottom-right',
            // }
            // );

            localStorage.setItem('pages', data.pages);
            localStorage.setItem('actions', data.actions);
            
            localStorage.setItem('shop_image', data['shop_image']);
            localStorage.setItem('shop_name', data['shop_name']);
            localStorage.setItem('shop_id', data['shop_id']);
            localStorage.setItem('sub_domain', data['sub_domain']);
            localStorage.setItem('srp','false');
            this.analytics.setUserId(data['shop_id']+"/"+data['shop_name']);
            localStorage.setItem('roles', 'false');
            localStorage.setItem('logged_in', 'true');
                          this.router.navigate(['/dashboard/dashboard']);
            
            // hardcode
          },
          (error: any) => {
            // this.analysis.logEvent("OTP Validation Failed");
            this.toastr.info('', 'Wrong OTP.', {
              positionClass: 'toast-bottom-right',
            });
          }
        );
    }
  }

  openContent(content: any) {
    this.modalService.open(content, { centered: true });
  }

  sendCounterOtp(content: any) {

    if (this.counter_id && this.counter_store_id && this.counter_pin) {
      this.loginService
        .counterSendOtp(
          this.counter_id,
          this.counter_store_id,
          this.counter_pin
        )
        .subscribe(
          (data: any) => {
            // this.analysis.logEvent("Counter OTP Validation Success");
            // this.responseMessage = data;
            // this.toastr.success('', this.responseMessage.message, {
            //   positionClass: 'toast-bottom-right',
            // }
            // );
            this.admin_mobile = data.mobile;
            this.modalService.open(content, { centered: true });
            this.timerFun();
          },
          (error: any) => {
            // this.analysis.logEvent("OTP Validation Failed");
            this.toastr.info('', error.error.message, {
              positionClass: 'toast-bottom-right',
            });
          }
        );
    }
  }

  counterResendOtp(type: any) {
    if (this.counter_id && this.counter_store_id && this.counter_otp) {
      var fd = new FormData();

      this.loginService
        .counterResendOtp(this.counter_id, this.shop_id, this.counter_otp, type)
        .subscribe(
          (data) => {
            // this.analysis.logEvent("Counter OTP Validation Success");
            // this.responseMessage = data;
            // this.toastr.success('', this.responseMessage.message, {
            //   positionClass: 'toast-bottom-right',
            // }
            // );
          },
          (error: any) => {
            // this.analysis.logEvent("OTP Validation Failed");
            this.toastr.info('', 'Wrong OTP.', {
              positionClass: 'toast-bottom-right',
            });
          }
        );
    }
  }

  setCounter() {
    this.counter = 60;
  }

  refreshPage(){
    location.reload();
  }

  timerFun() {
    if (this.run == true) {
      this.countDown = timer(0, this.tick)
        .pipe(take(this.counter))
        .subscribe(() => {
          --this.counter;

          if (this.counter == 0) {
            this.countDown.unsubscribe();
          }
        });
    }
    this.run = false;
  }
}
