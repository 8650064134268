<div class="parent mt-4">
    <div class="header">
        <!-- <mat-icon (click)="onclickBack()">arrow_back_ios</mat-icon> -->
        Details
    </div>
    <div class="content">
        <div class="details">
            <div class="title">Mobile Number</div>
            <div class="inp" *ngIf="!mobileVerified; else mobileVerifiedTemplate">
                <input #inputField class="{{mobileNumber.dirty && mobileNumber.invalid ? 'error' : ''}}" type="text" maxlength="10" style="color: #262626;"
                    [formControl]="mobileNumber" placeholder="Enter Mobile Number" (keyup.enter)="verifyOtp()" (keypress)="preventString($event)">
                <primary-btn (click)="verifyOtp()" [styles]="{'width':'150px'}">Verify</primary-btn>
            </div>
            <ng-template #mobileVerifiedTemplate>
                <div class="mobile-verified">
                    <div class="number">91-{{mobileNumber.value}} <span>{{userDetails?.first_name}}</span></div>
                    <div><mat-icon
                            (click)="goBackToFirstScreen()">close</mat-icon>
                    </div>
                </div>
                <div class="verified-batch"><img src="assets/images/Ok.svg" alt=""> Verified</div>
                <div class="customer-details">
                    <div class="detail-col">Name <span>{{userDetails?.first_name}}</span></div>
                    <div class="detail-col">Contact No <span>91-{{mobileNumber.value}}</span></div>
                    <div class="detail-col">Address <span>{{userDetails?.address}}</span></div>
                    <div class="detail-col" style="text-align: left;">Email<span>{{userDetails?.email ? userDetails?.email : '-'}}</span></div>
                    <div class="detail-col" style="text-align: center;">Pincode <span>{{userDetails?.pincode ? userDetails?.pincode : '-'}}</span></div>
                    <primary-btn (click)="openCustomerDetails()" [styles]="{'height':'35px'}">Edit
                        (Ctrl+E)</primary-btn>
                </div>
            </ng-template>
        </div>
        <div class="details" *ngIf="mobileVerified">
            <div class="amount_and_plan">
                <div>
                    <div>Plan</div>
                    <div class="mt-3">
                        <select class="form-select custom_select"
                        id="select_plan"
                        [disabled]="!allowed_plan_amount"
                        #plans
                        (change)="changeMinimumAmount(plans.value)"
                        (keydown.enter)="focus('sipamount')"
                        >
                            <option [disabled]="true">Choose Plan</option>
                            <option *ngFor="let data of get_plandataSource" value="{{ data.id }}">
                                {{ data.name }}
                              </option>
                        </select>
                    </div>
                </div>
                <div>
                    <div>SIP Amount*</div>
                    <div *ngIf="is_input_enabled">
                        <div class="mt-3">
                            <div class="input-group">
                              <div class="input-group-addon">
                                <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.01126 18C6.81306 18 6.65616 17.9571 6.54054 17.8714C6.42492 17.7857 6.31757 17.6829 6.21847 17.5629L0.545045 10.8257C0.412913 10.6886 0.322072 10.5686 0.272523 10.4657C0.23949 10.3457 0.222973 10.1657 0.222973 9.92571V9.36C0.222973 9.18857 0.272523 9.05143 0.371622 8.94857C0.487237 8.82857 0.627628 8.76857 0.792793 8.76857H2.13063C3.28679 8.76857 4.17042 8.61429 4.78153 8.30571C5.39264 7.99714 5.80556 7.58571 6.02027 7.07143C6.2515 6.55714 6.36712 5.96571 6.36712 5.29714C6.36712 4.66286 6.2515 4.08857 6.02027 3.57429C5.80556 3.04286 5.40916 2.62286 4.83108 2.31429C4.253 2.00571 3.43544 1.85143 2.37838 1.85143H0.56982C0.404655 1.85143 0.264264 1.8 0.148649 1.69714C0.0495496 1.57714 0 1.43143 0 1.26V0.591429C0 0.42 0.0495496 0.282858 0.148649 0.180001C0.264264 0.0600002 0.404655 0 0.56982 0H10.4302C10.5953 0 10.7275 0.0600002 10.8266 0.180001C10.9422 0.282858 11 0.42 11 0.591429V1.26C11 1.43143 10.9422 1.57714 10.8266 1.69714C10.7275 1.8 10.5953 1.85143 10.4302 1.85143H6.66441L6.04505 1.15714C7.11862 1.43143 7.8536 1.95429 8.25 2.72571C8.6464 3.49714 8.84459 4.38857 8.84459 5.4C8.84459 6.34286 8.6464 7.20857 8.25 7.99714C7.87012 8.78571 7.25901 9.42 6.41667 9.9C5.59084 10.3629 4.52553 10.6114 3.22072 10.6457L2.47748 10.6714L2.50225 9.64286L8.84459 17.2029C8.97673 17.34 9.04279 17.4686 9.04279 17.5886C9.04279 17.7257 8.99324 17.8286 8.89414 17.8971C8.79504 17.9657 8.67117 18 8.52252 18H7.01126ZM0.56982 5.96571C0.388138 5.96571 0.247748 5.91429 0.148649 5.81143C0.0495496 5.70857 0 5.56286 0 5.37429V4.73143C0 4.54286 0.0495496 4.39714 0.148649 4.29428C0.264264 4.19143 0.404655 4.14 0.56982 4.14H10.4302C10.5953 4.14 10.7275 4.19143 10.8266 4.29428C10.9422 4.39714 11 4.54286 11 4.73143V5.37429C11 5.56286 10.9422 5.70857 10.8266 5.81143C10.7275 5.91429 10.5953 5.96571 10.4302 5.96571H0.56982Z" fill="#636363"/>
                                    </svg>
                              </div>
                              <input
                                id="sipamount"
                                type="number"
                                class="form-control min_max_amount rid"
                                placeholder="Enter Amount"
                                [(ngModel)]="amount"
                                (keydown.enter)="focus('proceed_btn')"
                              />
                            </div>
                            <div
                              class="max_min">
                              <div>Min Amount: {{ minimumPlanAmount }}</div>
                              <div>Max Amount: {{ maxmum_plan_amount }}</div>
                            </div>
                          </div>
                    </div>
                    <div *ngIf="!is_input_enabled">
                        <div class="mt-3">
                            <select class="form-select mt-3"
                            [(ngModel)]="amount"
                            (keydown.enter)="focus('proceed_btn')"
                        style="color: #3b325f; font-size: 18px; height: 44px; width: 365px;"
                        id="sipamount"   [disabled]="!allowed_plan_amount">
                          <option *ngFor="let i of prefix" [value]="i">
                            {{ i }}
                          </option>
                        </select>
                        </div>
                        
                    </div>
                </div>
            </div>
            <button (click)="sendDataToPayment()" [disabled]="!(amount && plan_id)" id="proceed_btn" class="saveBtn">
                Proceed to Payment (f5)
            </button>
        </div>
    </div>
</div>
<ng-template #payment_modal let-modal class="pm">
    <div class="myModal" (keydown.control.s)="preventDefault($event)" 
    (keydown.control.s)="disable_payment_button?'':createJewelleryPlan(created_installment)">
      <div class="modal-header cus-mod-header">
        <div class="modal-title">Payment</div>
        <button
          type="button"
          style="border: none; border-top: none; outline: none"
          class="close"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="mt-1 payment_modal" (keydown.esc)="modal.dismiss('Cross click')"  (keydown.control.s)="disable_payment_button?'':''">
        <div class="pm_first" id="pm_first">
          <div class="mt-2 mb-1 ti">Payment Method</div>
          <div class="mt-4" (keydown.shift.enter)="addPaymentRow(i)" *ngFor="let payment of payment_arr; let i = index" (keydown.delete)="removePaymentRow(i);focus('payment_amount')">
            <div class="row">
              <div class="col-md-2" style="width: 20%;">
                <label>Enter Amount</label>
                <input
                  (keydown.enter)="focus('payment_method_' + i)"
                  (keydown.enter)="payment.options[0].amount ==''?payment.options[0].amount='0':''"
                  class="form-control"
                  placeholder="Enter Amount"
                  [id]="'payment_amount_'+i"
                  [(ngModel)]="payment.options[0].amount"
                  appTwoDigitDecimal
  
                />
              </div>
              <div class="col-md-2" style="width: 20%;">
                <label>Payment Method</label>
                <select
                  class="form-select"
                  (keydown.enter)="focus('payment_date_' + i)"
                  [id]="'payment_method_' + i"
                  [(ngModel)]="payment.mode"
                >
                  <option value=7>Cash</option>
                  <option value=1>Card</option>
                  <option value=3>NEFT/RTGS</option>
                  <option value=0>UPI/IMPS</option>
                  <option value=6>Cheque</option>
                  <!-- <option value=4>Online-Payment Gateway</option> -->
                </select>
              </div>
              <div class="col-md-2" style="width: 20%;">
                <label>Date</label>
                <input
                  (keydown.enter)="payment.mode==7?'':fovu.focus()"
                  class="form-control"
                  [id]="'payment_date_' + i"
                  type="date"
                  [(ngModel)]="payment.options[0].date"
                 
  
                />
              </div>
              <div class="col-md-2" style="width: 20%;">
                <label>Bank/POS Account</label>
                <!-- <input class="form-control" [(ngModel)]="payment.options[0].bank"> -->
                <ng-select
                  #fovu
                  class="form-select"
                  [searchable]="true"
                  [(ngModel)]="payment.options[0].bank"
                  [notFoundText]="'Type Valid Bank'"
                  [disabled]="payment.mode == 7"
                  [id]="'payment_bank_' + i"
                  [style]="payment.mode==7?{'background': '#DFDFDF!important'}:{}"
                  (keydown.enter)="focus('payment_info_'+i)"
                >
                  <ng-option
                    *ngFor="let account of account_list"
                    [value]="account.id"
                  >
                    {{ account.number }}
                  </ng-option>
                </ng-select>
              </div>
              <div class="col-md-2" style="width: 20%;">
                <label> UPI/UTR/Cheque No </label>
                <input
                  class="form-control"
                  [disabled]="payment.mode == 7"
                  [style]="payment.mode==7?{'background': '#DFDFDF!important'}:{}"
                  [id]="'payment_info_' + i"
                  [(ngModel)]="payment.options[0].payment_info"
                />
              </div>
            </div>
  
  
  
  
            <div style="display: flex;justify-content: right;">
              <div class="add_shift_enter mt-2" (click)="removePaymentRow(i);" style="color:red;opactiy:0.4" *ngIf="payment_arr.length>1">
                - Remove (delete)
              </div>
            </div>
            <hr class="mt-3" />
            <div  *ngIf="payment_arr.length-1==i" style="display: flex;justify-content: space-between;">
              <div class="add_shift_enter mt-2" (click)="addPaymentRow(i)">
                + Add (shift+enter)
              </div>
  
            </div>
          </div>
  
  
        </div>
  
        <div class="vertical-line"></div>
  
        <div class="pm_second">
          <div class="my-2 ti">Payment Summary</div>
          <div class="sb my-3">
            <div class="ps_l">Installment Amount</div>
            <div class="ps_r">₹ {{ amount  | number:'1.2-2'}}</div>
          </div>
          
        </div>
  
        <!-- model -->
      </div>
      <div
        class="f"
        style="
          justify-content: center;
          gap: 2%;
          margin-top: 3%;
          margin-bottom: 2%;
        "
      >
        <button class="save_b"  id="payment_save" style="width: 25%"  
        [disabled]="disable_payment_button" 
        (click)="createJewelleryPlan(created_installment)"
        >
          Save (Ctrl+S)
        </button>
        <!-- <button
          class="cancel_b"
          style="
            width: 25%;
            border: 1px solid var(--colors-fill-fill, #3268fb);
            color: #3268fb;
          "
  
        >
          Save & Print (Ctrl+P)
        </button> -->
      </div>
    </div>
  </ng-template>


  <ng-template #created_installment let-modal>
    <div class="myModal_new">
      <div class="modal-content p-4" style="width: 120%; height: 630px">
        <div class="modal-header" style="border: 0; height: 0">
          <h1 class="modal-title fbold"></h1>
          <button
            type="button"
            class="close closeButton btn"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="text-center">
            <img
              src="assets/images/tickmark.svg"
              class="img-responsive img-fluid mb-3"
            />
  
            <h1
              class="fbold"
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                color: #262626;
              "
            >
              Jewellery Plan Created Successfully !
            </h1>
            <div class="row">
              <div class="col-1"></div>
              <div class="col-8 ms-4">
                <table class="custom_table ms-1 pb-2" style="border:none">
                  <tbody>
                    <tr>
                      <td class="tname">Plan ID</td>
                      <td>:</td>
                      <td class="dname">{{ success_popup.plan_id }}</td>
                    </tr>
  
                    <tr>
                      <td class="tname">Plan Amount</td>
                      <td>:</td>
                      <td class="dname">{{ success_popup.plan_amount }}</td>
                    </tr>
                    <tr>
                      <td class="tname">Customer Phone</td>
                      <td>:</td>
                      <td class="dname">{{ success_popup.phone }}</td>
                    </tr>
                    <tr>
                      <td class="tname">Customer Name</td>
                      <td>:</td>
                      <td class="dname">{{ success_popup.name }}</td>
                    </tr>
                    <tr>
                      <td class="tname">Installment No</td>
                      <td>:</td>
                      <td class="dname">{{ success_popup.installment }}</td>
                    </tr>
                    <tr>
                      <td class="tname">Payment Mode</td>
                      <td>:</td>
                      <td class="dname">
                        {{ success_popup.payment_mode[0] }} <span></span>
                        {{ success_popup.payment_mode[1] ? "+Others" : "" }}
                      </td>
                    </tr>
                    <tr>
                      <td class="tname">Weight</td>
                      <td>:</td>
                      <td class="dname">{{ success_popup.weight }}</td>
                    </tr>
                    <tr>
                      <td class="tname"> {{success_popup.plan_type}}</td>
                      <td>:</td>
                      <td class="dname">{{ success_popup.accmulated_amount }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
  
            <button
              (click)="modal.dismiss('Cross click')"
              type="button"
              class="btn btn-primary mt-3 addbutton1"
            >
              Okay
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>