import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { ModalService } from './access-denied.service';

export class Actions {
  private constructor() {} // Private constructor to prevent instantiation

  static readonly AddRates = 1;
  static readonly EditCommodityName = 2;
  static readonly RateFilterDate = 3;
  static readonly AddPlanModel = 4;
  static readonly PausePlanModel = 5;
  static readonly EditPlanModel = 6;
  static readonly DeletePlanModel = 7;
  static readonly AddBankAccount = 8;
  static readonly AddPosAccount = 9;
  static readonly EditBankAccountDetails = 10;
  static readonly EnableOrDisableBankAccounts = 11;
  static readonly EnableOrDisablePOSAccounts = 12;
  static readonly DeleteBankOrPOSAccounts = 13;
  static readonly AddCustomerDetails = 14;
  static readonly ChoosePlanAndAmount = 15;
  static readonly AddPaymentMode = 16;
  static readonly EnterPlanID = 17;
  static readonly ChangeInstallmentDate = 18;
  static readonly ChangeRate = 19;
  static readonly EditInstallment = 21;
  static readonly DeleteInstallment = 22;
  static readonly CustomerLedgerSearch = 23;
  static readonly CustomerLedgerDownload = 24;
  static readonly ReportsSearch = 25;
  static readonly ReportPaymentModeFilter = 26;
  static readonly ReportDateFilter = 27;
  static readonly ReportDownload = 28;
  static readonly SettlementDateFilter = 29;
  static readonly SettlementDownload = 30;
  static readonly SettlementDetailsSearch = 31;
  static readonly SettlementDetailsDateFilter = 32;
  static readonly SettlementDetailsDownload = 33;
  static readonly CustomersSearch = 34;
  static readonly CustomersDownload = 35;
  static readonly EditCustomerDetails = 36;
  static readonly EnterDetailsSelectUsersAndSend = 37;
  static readonly EditPaymentMode = 38;
  static readonly CancelPlan = 39;
  static readonly SetupAdvanceBooking = 40;
  static readonly NewBooking = 41;
  static readonly ChangeRateBooking = 42;
  static readonly EditBooking = 43;
  static readonly CompleteBooking = 44;
  static readonly AddAdvance = 45;
  static readonly CancelBooking = 46;
  static readonly DownloadBooking = 47;
  static readonly ChangeOrderDate = 48;
  static readonly ChangeOrderRate = 49;
  static readonly AddNewPurity = 50;
  static readonly ChangePaymentDate = 51;
  static readonly AddOrder = 52;
  static readonly ChangeOrderStatus = 53;
  static readonly AssignVendor = 54;
  static readonly AddNewVendor = 55;
  static readonly DownloadOrders = 56;
  static readonly AddAdvanceOrder = 57;
  static readonly AddOldGold = 58;
  static readonly OrderPrint = 59;
  static readonly EditOrder = 60;
  static readonly CancelOrder = 61;
  static readonly ChangeAdvancePaymentDate = 62;
  static readonly AddCounter = 63
  static readonly EditRolesPermission   = 64
  static readonly ChangePin = 65
  static readonly DeleteCounter = 66
  static readonly EnableDisableStatus = 67
  static readonly AdditionalInstallment = 68;
  static readonly SetupDigitalCoin = 69;
  static readonly DownloadDigitalCoinOrders = 70;
  static readonly CancelDigitalCoinOrder = 71;
  static readonly CustomerDigitalCoinUserVault = 72;
  static readonly ChangePaymentDateDigitalCoinOrder = 73;
  static readonly UpdatePaymentDigitalCoinOrder = 74;
  static readonly ChangeRepairDate = 75;
  
  static readonly ChangeRepairPaymentDate = 76;
static readonly AddRepair = 77;
static readonly ChangeRepairStatus = 78;
static readonly AssignRepairVendor = 79;
static readonly AddRepairVendor = 80;
static readonly DownloadRepairs = 81;
static readonly AddRepairAdvance = 82;
static readonly RepairPrint = 83;
static readonly EditRepair = 84;
static readonly CancelRepair = 85;
static readonly ChangeRepairAdvancePaymentDate = 86;

}

@Injectable({
  providedIn: 'root',
})
export class PageAccessService {
  actions = localStorage.getItem('actions');
  can_access_pages = localStorage.getItem('pages')!;

  allowedActions: { [key: string]: boolean } = {};
  constructor(private router: Router,
    private modalService: ModalService) {
    this.actionHandler();
  }


  check(data: any) {
    console.log(this.can_access_pages);
    var list = this.can_access_pages.split(',');

    for (let i = 0; i < list.length; i++) {
      if (list[i] == data) {
        return true;
      }
    }
    return false;
  }

  check2(data: any) {
    var list = this.can_access_pages.split(',');
    var roles = localStorage.getItem('roles');
    console.log("roes",roles)
    if (list.includes(data) || roles == 'true') {
      return true;
    }
    return false;
  }

  checkAction(data: any) {
    var list = this.actions!.split(',').map(Number);
    this.actionHandler();

    if (list.includes(data)) {
      return true;
    }
    return false;
  }
  actionHandler() {
    if (this.can_access_pages == 'admin') {
      for (const actionName in Actions) {
        this.allowedActions[actionName as keyof typeof Actions] = true;
      }
    } else {
      const actionNumbersToAllow: number[] =
        this.actions!.split(',').map(Number);
      console.log(actionNumbersToAllow);
      for (const actionName in Actions) {
        const actionValue = Actions[actionName as keyof typeof Actions];
        const shouldAllow = actionNumbersToAllow.includes(Number(actionValue));
        this.allowedActions[actionName] = shouldAllow;
      }
    }
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {

 
    var roles = localStorage.getItem('roles');
    if (roles == 'false') {
      if (this.check(route.data['value'])) {
        return true;
      }
    }
    if (roles == 'true') {
      return true;
    }
    else{
      const is_counter =  localStorage.getItem('srp');
      if (is_counter == 'false') {
        return false;
      }
      else{
        this.modalService.openAccessDeniedModal();
        return false;
      }

    }


  }
}
