<div class="parent">
    <div class="header">
        <div class="text">Payment</div>
        <mat-icon (click)="dialogRef.close()">close</mat-icon>
    </div>
    <div class="content">
        <div class="summary">
            <div class="title">Payment Summary</div>
            <div class="summary-content">
                <div class="key-value-pair">
                    <div class="key">Weight</div>
                    <div class="value">{{data.paymentSummary.weight | number:'1.0-2'}}</div>
                </div>
                <div class="key-value-pair bottom-border">
                    <div class="key">Rate/gm</div>
                    <div class="value">₹ {{data.paymentSummary.rate}}</div>
                </div>
                <div class="key-value-pair">
                    <div class="key">Sub Total</div>
                    <div class="value">₹ {{data.paymentSummary.sub_total}}</div>
                </div>
                <div class="key-value-pair">
                    <div class="key">VA</div>
                    <div class="value">₹ {{data.paymentSummary.va_amount}}</div>
                </div>
                <div class="key-value-pair">
                    <div class="key">CGST</div>
                    <div class="value">₹ {{data.paymentSummary.cgst_amount}}</div>
                </div>
                <div class="key-value-pair">
                    <div class="key">SGST</div>
                    <div class="value">₹ {{data.paymentSummary.sgst_amount}}</div>
                </div>
                <div class="key-value-pair">
                    <div class="key">IGST</div>
                    <div class="value">₹ {{data.paymentSummary.igst_amount}}</div>
                </div>
                <div class="key-value-pair bottom-border">
                    <div class="key">Round Off</div>
                    <div class="value">{{data.paymentSummary.round_off}}</div>
                </div>
                <div class="key-value-pair">
                    <div class="key">Amount</div>
                    <div class="value">₹ {{data.paymentSummary.total_amount}}</div>
                </div>
            </div>
        </div>
        <div class="methods">
            <div class="title">Payment Method</div>
            <div class="inp-con">
                <div class="inp-row {{i === paymentOptions.length-1 ? 'last-row' : ''}}"
                    *ngFor="let option of paymentOptions; let i=index">
                    <button class="row-action" [disabled]="!isRowFormValid(i)"
                        (click)="i === paymentOptions.length-1 ? addInputRow() : deleteInputRow(i)">
                        {{i === paymentOptions.length-1 ? '+ Add (shift+enter)' : '- Remove'}}
                    </button>
                    <div class="inp">
                        <div class="title">Enter Amount</div>
                        <mat-form-field appearance="outline">
                            <mat-icon matPrefix style="font-size: 120%;">currency_rupee</mat-icon>
                            <input type="number" class="rid" [(ngModel)]="option.options[0].amount" matInput
                                placeholder="Enter Amount" appNextInput>
                        </mat-form-field>
                    </div>
                    <div class="inp">
                        <div class="title">Payment Method</div>
                        <mat-form-field appearance="outline">
                            <mat-select (valueChange)="option.type = $event; onPaymentMethodChange(option)" [value]="option.type" #payMethod (keydown.enter)="focusNextInput($event, 'nextInput')">
                                <mat-option *ngFor="let method of paymentMethods"
                                    [value]="method">{{method}}</mat-option>
                            </mat-select>
                            <mat-icon matSuffix>expand_more</mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="inp">
                        <div class="title">Date</div>
                        <mat-form-field appearance="outline">
                            <input matInput #nextInput [(ngModel)]="option.options[0].date" [matDatepicker]="picker" (ngModelChange)="checkRowValidity(i)" 
                                [disabled]="!allowed_actions['ChangePaymentDateDigitalCoinOrder']" placeholder="dd/mm/yyyy"
                                (keydown.enter)="openDatepickerOnKey($event)" appNextInput>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="inp" *ngIf="option.type !== 'Cash'">
                        <div class="title">{{ option.type === 'Card' ? 'POS' : 'Bank'}}</div>
                        <mat-form-field appearance="outline">
                            <mat-select (valueChange)="option.options[0].bank = $event" #posInput
                                [value]="option.options[0].bank" (keydown.enter)="focusInput($event, 'nextInputs')">
                                <mat-option
                                    *ngFor="let data of option.type === 'Card' ? posDropDownList : bankDropDownList"
                                    [value]="data.id">{{data.number}}</mat-option>
                            </mat-select>
                            <mat-icon matSuffix>expand_more</mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="inp" *ngIf="option.type !== 'Cash'">
                        <div class="title">{{
                            option.type === 'Card' ? 'Card Details' :
                            option.type === 'UPI/MPS' ? 'UPI ID' :
                            option.type === 'RTGS/NEFT' ? 'UTR Number' :
                            'Cheque No'
                            }}</div>
                        <mat-form-field appearance="outline">
                            <input matInput #nextInputs [(ngModel)]="option.options[0].payment_info" placeholder="" (keydown.enter)="focus('save_btns')">
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <button (click)="saveBooking()" id="save_btns" class="saveBtn">Save(Ctrl+S)</button>
</div>