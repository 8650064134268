import { StringLike } from '@firebase/util';

export class Ledger {
  id!: string;
  code!: string;
  plan_id!: string;
  plan_name!: string;
  customer_name!: string;
  phone!: string;
  plan_type!: string;
  cost!: number;
  duration!: number;
  created_on!: string;
  weight_or_amount!: string;
  status!: string;
  installments!: string;
}
//models declerations
export class Emi {
  plan_id!: string;
  code!: string;
  installments!: string;
  plan_name!: string;
  plan_date!: string;
  amount!: number;
  rate!: number;
  weight!: number;
  payment_mode!: string;
  payment_id!: string;
  settlement_id!: string;
  settlement_date!: string;
}

export class EMI_DATA {
  amount!: string;
  code!: string;
  duration!: number;
  installments!: string;
  start_date!: string;
  status!: string;
  total_weight!: string;
  type!: string;
}

export class ClosePlan {
  id!: string;
  closing_date!: string;
  invoice!: string;
  phone!:string;
  otp!:string;
  constructor(id: string, closing_date: string, invoice: string,phone:string,otp:string) {
    this.id = id;
    this.closing_date = closing_date;
    this.invoice = invoice;
    this.phone = phone;
    this.otp = otp;
  }
}


